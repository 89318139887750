import React from "react";
import PropTypes from "prop-types";
import { Typography, Box, Button, Divider } from "@mui/material";
import application from "../../assets/svgs/sevisfee.svg";
import payment1 from "../../assets/svgs/card-pos (2).svg";
import payment2 from "../../assets/svgs/reciept.svg";
import payment3 from "../../assets/svgs/calendar (3).svg";
import { fDate } from "../../utils/formatTime";

RecentPayment.propTypes = {
  heading: PropTypes.string.isRequired,
  transactions: PropTypes.string.isRequired,
};

const style = {
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  textAlign: "center",
};

function RecentPayment({ heading, transactions }) {
  let orderType;
  let amount;
  let date;
  let status;
  let length;
  // eslint-disable-next-line array-callback-return
  transactions.map((transaction) => {
    orderType = transaction.order_type;
    amount = transaction.amount;
    date = transaction.date;
    status = transaction.status;
    length = transaction.length
  });
  return (
    <Box sx={{ mt: { xs: 4, md: 1, lg: 5 } }}>
      <Box>
        <Typography
          variant="body1"
          sx={{ fontWeight: 500, color: "#000", mb: 1 }}
        >
          {heading}{" "}
        </Typography>
      </Box>

      <Box
        sx={{
          py: { xs: 4, md: 2, lg: transactions.length > 0 ? 5:6 },
          px: 2,
          boxShadow: 0,
          borderRadius: 0,
          border: { xs: "1px solid #939697" },
          display: "flex",
          flexDirection: { xs: "column", sm: "row", md: "column", lg: "row" },
          alignItems: "center",
          justifyContent: "space-evenly",
          gap: { xs: 4, md: 2, lg: 4 },
        }}
        container
      >
        <Box style={style}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gap: 2,
            }}
          >
            <img src={application} alt="application fee" />
            <Box>
              <Box
                sx={{
                  mb: 1,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: 1,
                }}
              >
                <Typography
                  variant="subtitle2"
                  sx={{ color: "#b6b8b9", fontWeight: 500 }}
                >
                  Payment Type{" "}
                </Typography>
                <img src={payment1} alt="payment type" />
              </Box>
              <Typography
                variant="h6"
                sx={{ color: "#626668", fontWeight: 500, textDecoration:"uppercase" }}
              >
                {orderType}
              </Typography>
            </Box>
          </Box>
        </Box>

        <Divider
          orientation="vertical"
          sx={{ display: { xs: "none", sm: "flex" } }}
          flexItem
        />

        <Divider
          sx={{
            display: { sm: "none" },
            width: "100%",
          }}
        />

        <Box style={style} item xs={12} sm={2} lg={3.3}>
          <Box
            sx={{
              mb: 1,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gap: 1,
            }}
          >
            <Typography
              variant="subtitle2"
              sx={{ color: "#b6b8b9", fontWeight: 500 }}
            >
              Total Cost
            </Typography>
            <img src={payment2} alt="payment type" />
          </Box>

          <Typography variant="h6" sx={{ color: "#626668", fontWeight: 500 }}>
            {amount}{" "}
          </Typography>
        </Box>

        <Divider
          orientation="vertical"
          sx={{ display: { xs: "none", sm: "flex" } }}
          flexItem
        />

        <Divider
          sx={{
            display: { sm: "none" },
            width: "100%",
          }}
        />

        <Box style={style} item xs={12} sm={4} lg={3.5}>
          <Box
            sx={{
              mb: 1,
              display: "flex",
              alignItems: "center",
              gap: 1,
              justifyContent: "center",
            }}
          >
            <Typography
              variant="subtitle2"
              sx={{ color: "#b6b8b9", fontWeight: 500 }}
            >
              {fDate(date)}{" "}
            </Typography>
            <img src={payment3} alt="payment type" />
          </Box>
          <Button
            sx={{ background: "#e9f7f0", width: "100px", color: "#168250" }}
          >
            {status}{" "}
          </Button>
        </Box>
      </Box>
    </Box>
  );
}

export default RecentPayment;
