import React from "react";
import { Typography, Divider, Box,} from "@mui/material";
import logo from "../../../assets/svgs/gtb.svg";


function Transfer() {
  return (
    <>
      <Box
        sx={{
          border: "1px solid #BAC6D2",
          display: "flex",
          py: {xs:6},
          alignItems: "center",
          justifyContent: "center",
          borderRadius:1,
          gap:3
        }}
      >
        <Box>
          <img src={logo} alt="bank logo" />
          <Typography variant="subtitle1" sx={{mt:1}}>Guaranty Trust Co.</Typography>
          <Typography>Educarts Nig Ltd</Typography>
        </Box>

        <Divider
          orientation="vertical"
          flexItem
          sx={{ border: "1px solid #bac6d2" }}
        />

        <Box>
          <Typography variant="subtitle1">Account Number</Typography>
          <Typography>01234567892</Typography>
          <Typography variant="subtitle1" sx={{mt:1}}>Account Type</Typography>
          <Typography>Current</Typography>
        </Box>
      </Box>
    </>
  );
}

export default Transfer;
