import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
// @mui
import {
  Grid,
  Stack,
  Box,
  Divider,
  Container,
  Typography,
  Paper,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import sevis from "../assets/svgs/united.svg"

// sections
import {
  PaymentMethod,
  OrderSummary,
  TopBar,
  Card,
  Transfer,
} from "../sections/@dashboard/payment";
import axiosClient from "../axios-client";
// ----------------------------------------------------------------------

const StyledHeader = styled("div")(({ theme }) => ({
  background: "linear-gradient(270deg, #E9EDF1 0%, #E9EDF1 60%, #E9EDF1 100%)",
  paddingTop: theme.spacing(3),
  paddingBottom: theme.spacing(3),
  textAlign: "center",
  "&:after": {
    content: '""',
    position: "absolute",
    width: "60px",
    height: "60px",
    backgroundImage: `url("../assets/svgs/navbg.svg")`,
    // opacity: "0%",
    top: "90px",
    right: "5px",
  },
}));

export default function PaymentFlow() {
  const [transfer, setTransfer] = useState(false);
  const [card, setCard] = useState(false);
  const token = localStorage.getItem("access");
  const [transactions, setTransactions] = useState([]);

  const handleTransfer = () => {
    setTransfer(true);
    setCard(false);
  };
  const handleCard = () => {
    setCard(true);
    setTransfer(false);
  };

  useEffect(() => {
    const handleTransactions = () => {
      axiosClient
        .get("/v1/dashboard/order-history/", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          if (response.data.message === "Successful") {
            setTransactions(response.data.data);
          }
        })
        .catch((err) => {
          console.log(err.message);
        });
    };

    handleTransactions();
  }, []);

  return (
    <>
      <Helmet>
        <title> Payments | Educart </title>
      </Helmet>

      <Stack>
        <StyledHeader>
          <Container>
            <Stack
              sx={{
                flexDirection: "row",
                alignItems: "center",
                display: "flex",
                justifyContent: "flex-start",
                gap: 0.8,
                height:"30px"
              }}
             
            >
              <img src={sevis} alt ="sevis logo"/>
              <Typography variant="h6" sx={{color:"#151a1d"}}>SEVIS FEE</Typography>
              <Divider
                  orientation="vertical"
                  flexItem
                  sx={{ border: "1px solid #1e1e1e" }}
                />
              <Typography variant="body2">
                Complete the following information below to complete payments
              </Typography>
            </Stack>
          </Container>
        </StyledHeader>

        <Box>
          <TopBar transactions={transactions} />
        </Box>

        <Grid container rowSpacing={{ xs: 6, md: 2, lg: 1 }}>
          {/* PAYMENT METHOD */}
          <Grid item xs={12} md={6} lg={6}>
            <Paper
              sx={{
                display: "flex",
                flexDirection: "column",
                mx: 2,
                pt: 5,
                pb: 10,
                mt: 2,
                px: 4,
                borderRadius: 2,
                boxShadow: 1,
                height:{md:!card && !transfer ? "450px": "810px"}
              }}
            >
              <Box>
                <Typography variant="subtitle1" sx={{ mb: 2, color: "#626668" }}>
                  Select a payment method
                </Typography>
                <PaymentMethod
                  handleCard={handleCard}
                  handleTransfer={handleTransfer}
                  card={card}
                  transfer={transfer}
                />
                <Divider sx={{ color: "grey", mt: 3, mb: 4 }} />
              </Box>

              {card ? <Card /> : transfer ? <Transfer /> : ""}
            </Paper>
          </Grid>

          {/* ORDER SUMMARY */}
          <Grid item xs={12} md={6} lg={6}>
            <Paper
              sx={{
                mx: 2,
                pt: 5,
                pb: 10,
                mt: 2,
                px: 4,
                display: "flex",
                flexDirection: "column",
                borderRadius: 1,
                boxShadow: 1,
                height:{md:!card && !transfer ? "450px": "810px"}
              }}
            >
              <Box>
                {" "}
                <Typography variant="subtitle1" sx={{ mb: 2, color: "#626668" }}>
                  Order Summary{" "}
                </Typography>
                <Typography
                  variant="caption"
                  sx={{ textAlign: "center", mb: 2, color: "#626668" }}
                >
                  Review your payment summary before you proceed{" "}
                </Typography>
                <Divider sx={{ py: 1 }} />
                <OrderSummary
                  transactions={transactions}
                  card={card}
                  transfer={transfer}
                />
              </Box>
            </Paper>
          </Grid>
        </Grid>
      </Stack>
    </>
  );
}
