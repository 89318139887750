import React from "react";
import PropTypes from "prop-types";

import {
  Typography,
  Box,
  Grid,
} from "@mui/material";
import { AppWidgetSummary } from "../../sections/@dashboard/app";
import send from "../../assets/svgs/send2.svg";
import track from "../../assets/svgs/track.svg";

QuickActions.propTypes = {
  heading: PropTypes.string.isRequired,
};

function QuickActions({ heading }) {
  return (
    <Grid sx={{}}>
      <Box sx={{ display: "flex", justifyContent: "space-between"}}>
        <Typography variant="body1" 
          sx={{ fontWeight: 500, color: "#000", mb: 1 }}>
          {heading}{" "}
        </Typography>
      </Box>

      <Box
        spacing={3}
        sx={{
          width:"100%",
          display: "flex",
          flexDirection: { xs: "column", sm: "row" },
          gap: { xs: 2, sm: 6, md:2, lg: 4, xl: 5 },
        }}
      >
        <AppWidgetSummary
          title="Make Payments"
          color={"#20466f"}
          borderColor={"#4d6b8c"}
          bgcolor={"#e9edf1"}
          icon={send}
          link={"/payments"}
        />
        <AppWidgetSummary
          title="Track Payments"
          color={"#c77400"}
          borderColor={"#784600"}
          bgcolor={"#fbf2e6"}
          icon={track}
          link={"#"}
        />
      </Box>

    </Grid>
  );
}

export default QuickActions;
