import React, { useState } from "react";
import Stepper from "@mui/material/Stepper";
import RegisterPage from "./RegisterPage";
import RegisterPage2 from "./RegisterPage2";
import RegisterPage3 from "./RegisterPage3";

// const stepStyle = {
//   boxShadow: 2,
//   backgroundColor: "rgba(0,0,0,0.1)",
//   padding: 2,
//   "& .Mui-active": {
//     "&.MuiStepIcon-root": {
//       color: "warning.main",
//       fontSize: "2rem",
//     },
//     "& .MuiStepConnector-line": {
//       borderColor: "warning.main"
//     }
//   },
//   "& .Mui-completed": {
//     "&.MuiStepIcon-root": {
//       color: "secondary.main",
//       fontSize: "2rem",
//     },
//     "& .MuiStepConnector-line": {
//       borderColor: "secondary.main"
//     }
//   }
// }

// function CustomStepper(props) {
//   return (
//     <Stepper
//       { ...props }
//       activeColor="#ffd813"
//       defaultColor="#eee"
//       completeColor="#ffbd13"
//       activeTitleColor="#fff"
//       completeTitleColor="#eee"
//       defaultTitleColor="#bbb"
//       circleFontColor="#000"
//       completeBarColor="#ffbd13"/>
//   );
// }

function Steps() {
  const [activeStep, setActiveStep] = useState(0);

  const steps = [
    { title: "Step 1" },
    { title: "Step 2" },
    { title: "Step 3" },
  ];

  const handleStep = () => {
    if (activeStep !== steps.length - 1) {
      setActiveStep(activeStep + 1);
    }
  };

  function getSectionComponent() {
    switch (activeStep) {
      case 0:
        return <RegisterPage handleStep={handleStep} />;
      case 1:
        return <RegisterPage2 handleStep={handleStep} />;
      case 2:
        return <RegisterPage3 handleStep={handleStep} />;
      default:
        return null;
    }
  }

  return (
    <div className="container">
      <Stepper steps={steps} activeStep={activeStep} />
      <div>
        
        {getSectionComponent()}
        {/* {activeStep !== 0 && activeStep !== steps.length - 1 && (
          <button type="button" onClick={() => setActiveStep(activeStep - 1)}>
            Previous
          </button>
        )}
        {activeStep !== steps.length - 1 && (
          <button type="button" onClick={() => setActiveStep(activeStep + 1)}>
            Next
          </button>
        )} */}
      </div>
    </div>
  );
}

export default Steps;
