import * as React from "react";
import { NavLink as RouterLink } from "react-router-dom";
// @mui
import { Box, ListItemText, Collapse, IconButton } from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";

//
import { StyledNavItem, StyledNavItemIcon } from "./styles";
import SvgColor from "../svg-color";
import Iconify from "../iconify";

// ----------------------------------------------------------------------

const icon = (name) => (
  <SvgColor
    src={`/assets/icons/navbar/${name}.svg`}
    sx={{ width: 1, height: 1 }}
  />
);
// ----------------------------------------------------------------------

export default function NavSection() {
  const [open, setOpen] = React.useState(false);

  const data = [
    {
      path: "/account/profile",
      title: "profile",
    },
    {
      path: "/account/identification",
      title: "identification",
    },
    {
      path: "/account/security",
      title: "security",
    },
  ];

  const handleClick = () => {
    setOpen(!open);
  };
  return (
    <Box>
      <StyledNavItem
        component={RouterLink}
        to="/home"
        sx={{
          color: "#F4F9FA",
          "&.active": {
            color: "#F4F9FA",
            bgcolor: "action.selected",
            fontWeight: "fontWeightBold",
          },
        }}
      >
        <StyledNavItemIcon>{icon("ic_home")}</StyledNavItemIcon>
        <ListItemText disableTypography primary="Home" />
      </StyledNavItem>

      <StyledNavItem
        component={RouterLink}
        to="/inbox"
        sx={{
          color: "#F4F9FA",
          "&.active": {
            color: "#F4F9FA",
            bgcolor: "action.selected",
            fontWeight: "fontWeightBold",
          },
        }}
      >
        <StyledNavItemIcon>{icon("ic_inbox")}</StyledNavItemIcon>
        <ListItemText disableTypography primary="inbox" />
      </StyledNavItem>

      <StyledNavItem
        component={RouterLink}
        to="/payments"
        sx={{
          color: "#F4F9FA",
          "&.active": {
            color: "#F4F9FA",
            bgcolor: "action.selected",
            fontWeight: "fontWeightBold",
          },
        }}
      >
        <StyledNavItemIcon>{icon("ic_payment")}</StyledNavItemIcon>
        <ListItemText disableTypography primary="Payments" />
      </StyledNavItem>

      <StyledNavItem
        component={RouterLink}
        to="/support"
        sx={{
          color: "#F4F9FA",
          "&.active": {
            color: "#F4F9FA",
            bgcolor: "action.selected",
            fontWeight: "fontWeightBold",
          },
        }}
      >
        <StyledNavItemIcon>{icon("ic_support")}</StyledNavItemIcon>
        <ListItemText disableTypography primary="support" />
      </StyledNavItem>

      <StyledNavItem
        component={RouterLink}
        to="/transactions"
        sx={{
          color: "#F4F9FA",
          "&.active": {
            color: "#F4F9FA",
            bgcolor: "action.selected",
            fontWeight: "fontWeightBold",
          },
        }}
      >
              <StyledNavItemIcon>{icon("ic_transaction")}</StyledNavItemIcon>

        <ListItemText disableTypography primary="Transactions" />
      </StyledNavItem>

      <StyledNavItem
        component={RouterLink}
        to="/consultation"
        sx={{
          color: "#F4F9FA",
          "&.active": {
            color: "#F4F9FA",
            bgcolor: "action.selected",
            fontWeight: "fontWeightBold",
          },
        }}
      >
              <StyledNavItemIcon>{icon("ic_consultation")}</StyledNavItemIcon>

        <ListItemText disableTypography primary="Consultation" />
      </StyledNavItem>

      <StyledNavItem
        sx={{
          color: "#F4F9FA",
          "&.active": {
            color: "#F4F9FA",
            bgcolor: "action.selected",
            fontWeight: "fontWeightBold",
          },
        }}
      >
        <Box
          onClick={handleClick}
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <StyledNavItemIcon>{icon("ic_account")}</StyledNavItemIcon>
          <ListItemText disableTypography primary="account" />
          <Box sx={{ ml: 2, mt: 0.6 }}>
            {open ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
          </Box>
        </Box>
      </StyledNavItem>
      <Collapse in={open} timeout="auto" unmountOnExit>
        {data.map((item) => (
          <StyledNavItem
            sx={{
              color: "#F4F9FA",
              "&.active": {
                color: "#F4F9FA",
                bgcolor: "action.selected",
                fontWeight: "fontWeightBold",
              },
            }}
            key={item.title}
            component={RouterLink}
            to={item.path}
            sx={{
              color: "#F4F9FA",
              pl: 10,
              "&.active": {
                color: "orange",
              },
            }}
          >
            <ListItemText sx={{}} disableTypography primary={item.title} />
          </StyledNavItem>
        ))}
      </Collapse>

      {/* <StyledNavItem
        component={RouterLink}
        to="/settings"
        sx={{
          color: "#F4F9FA",
          "&.active": {
            color: "#F4F9FA",
            bgcolor: "action.selected",
            fontWeight: "fontWeightBold",
          },
        }}
      >
        <StyledNavItemIcon>{icon("ic_settings")}</StyledNavItemIcon>
        <ListItemText disableTypography primary="settings" />
      </StyledNavItem> */}
    </Box>
  );
}

// ----------------------------------------------------------------------

// NavItem.propTypes = {
//   item: PropTypes.object,
// };

// function NavItem({ item }) {
//   const { title, path, icon, info } = item;

//   return (
//     <StyledNavItem
//       component={RouterLink}
//       to="/home"
//       sx={{
//         color: "#F4F9FA",
//         "&.active": {
//           color: "#F4F9FA",
//           bgcolor: "action.selected",
//           fontWeight: "fontWeightBold",
//         },
//       }}
//     >
//       <StyledNavItemIcon>{icon("ic_home")}</StyledNavItemIcon>

//       <ListItemText disableTypography primary="Home" />

//       {info && info}
//     </StyledNavItem>
//   );
// }
