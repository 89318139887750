import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
// @mui
import { Grid, Stack, Box } from "@mui/material";

// components
import QuickActions from "./Dash/QuickActions";
import PriceList from "./Dash/PriceList";
import Recommended from "./Dash/Recommended";
import RecentPayment from "./Dash/RecentPayment";
import TransactionHistory from "./Dash/TransactionHistory";
import NoRecentPayment from "./Dash/NoRecentPayment";
import NoTransactionHistory from "./Dash/NoTransactionHistory";
// import IncompleteRegDashboard from "./Dash/IncompleteRegDashboard";
import TopBar from "./Dash/TopBar";
import axiosClient from "../axios-client";
// sections
// import hand from "../assets/svgs/Waving Hand.svg";

// ----------------------------------------------------------------------
// eslint-disable-next-line no-empty-pattern
export default function DashboardAppPage() {
  const token = localStorage.getItem("access");

  const [transactions, setTransactions] = useState([]);

  useEffect(() => {
    const handleTransactions = () => {
      axiosClient
        .get("/v1/dashboard/order-history/", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          if (response.data.message === "Successful") {
            setTransactions(response.data.data);
          }
          console.log(response);
        })
        .catch((err) => {
          console.log(err);
        });
    };

    handleTransactions();
  }, []);

  return (
    <>
      <Helmet>
        <title> Dashboard | Educart </title>
      </Helmet>

      {/* <IncompleteRegDashboard /> */}

      <Stack>
        <Box sx={{ display: { xs: "none", lg: "block" }, mt: { lg: 2 } }}>
          <TopBar />
        </Box>
        <Grid
          container
          rowSpacing={{ xs: 6, md: 2, lg: 3 }}
          columnSpacing={{ md: 2, lg: 3 }}
        >
          {/* QUICK ACTIONS AND RECENT PAYMENTS */}
          <Grid sx={{ height: { lg: 500 } }} item xs={12} sm={12} md={6} lg={8}>
            <QuickActions heading="Quick Actions" />
            {transactions.length > 0 ? (
              <RecentPayment
                transactions={transactions}
                heading="Recent Payments"
              />
            ) : (
              <NoRecentPayment heading="Recent Payments" />
            )}
          </Grid>

          {/* PRICE LIST */}
          <Grid sx={{ height: { lg: 500 } }} item xs={12} md={6} lg={4}>
            <PriceList heading="Price list" text="Add new payment" />
          </Grid>

          {/* TRANSACTION HISTORY */}
          <Grid item xs={12} md={6} lg={8}>
            {transactions.length > 0 ? (
              <TransactionHistory
              transactions={transactions}
                heading="Transaction History"
                text="Add new payment"
              />
            ) : (
              <NoTransactionHistory heading="Transaction History" />
            )}
          </Grid>

          {/* BLOG/ARTICLES */}
          <Grid item xs={12} md={6} lg={4}>
            <Recommended heading="Blog/Articles" text="See all" />
          </Grid>
        </Grid>
      </Stack>
    </>
  );
}
