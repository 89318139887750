import React from "react";
import PropTypes from "prop-types";
import { Typography, Box, Stack, Grid } from "@mui/material";
import card from "../../assets/svgs/card-pos (2).svg";
import note from "../../assets/svgs/note-2.svg";
import cal from "../../assets/svgs/calendar (2).svg";
import house from "../../assets/svgs/house-2.svg";
import Iconify from "../../components/iconify";

Recommended.propTypes = {
  heading: PropTypes.string.isRequired,
};

function Recommended({ heading}) {
  const details = [
    {
      title: "Free Apartments",
      text: "Amber Hostels is offering...",
      image: house,
    },
    {
      title: "Educart Awards",
      text: "Read about Anita, the best...",
      image: note,
    },
    {
      title: "Payment Tracking",
      text: "Educarts will now charge...",
      image: card,
    },
    {
      title: "Payments to Oxford",
      text: "Your Payments to Oxford is...",
      image: cal,
    },

  ];

  return (
    <Grid>
      <Box sx={{ display: "flex", justifyContent: "space-between", }}>
        <Typography
          variant="body1"
          sx={{ fontWeight: 500, color: "#000", mb: 1 }}
        >
          {heading}{" "}
        </Typography>
      </Box>

      <Stack>
        {details.map((item, index) => (
          <Box key={index+1} sx={{ px: 2, py: 1, backgroundColor: "#fff" }}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                mb: 3,
                borderBottom: "1px solid gray",
                pb: 2,
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: 2,
                }}
              >
                <img src={item.image} alt="recommended" />
                <Box>
                  <Typography
                    variant="body2"
                    sx={{ color: "#444849", fontWeight: 500 }}
                  >
                    {item.title}{" "}
                  </Typography>
                  <Typography
                    variant="caption"
                    sx={{ color: "#939697", fontWeight: 400 }}
                  >
                    {item.text}{" "}
                  </Typography>
                </Box>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  gap: 1,
                  alignItems: "center",
                  textAlign: "center",
                }}
              >
                <Box
                  sx={{
                    width: 28,
                    height: 28,
                    borderRadius: "50%",
                    border: "1px solid gray",
                    cursor: "pointer",
                    alignItems: "center",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <Iconify
                    icon="eva:trash-2-outline"
                    sx={{
                      color: "gray",
                      width: 16,
                      height: 16,
                    }}
                  />
                </Box>
                <Box
                  sx={{
                    width: 28,
                    height: 28,
                    borderRadius: "50%",
                    border: "1px solid gray",
                    cursor: "pointer",
                    alignItems: "center",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <Iconify
                    icon="eva:star-outline"
                    sx={{
                      color: "gray",
                      width: 16,
                      height: 16,
                    }}
                  />
                </Box>
              </Box>
            </Box>
          </Box>
        ))}
      </Stack>
    </Grid>
  );
}

export default Recommended;
