import React, { useEffect, useRef, useState } from 'react';
import { GoChevronUp, GoChevronDown } from 'react-icons/go';

import { options } from '../constants/globalConstants';

// eslint-disable-next-line react/prop-types
const Dropdown = ({ label, required, value, onChange }) => {
  const [isOpen, setIsOpen] = useState(false);
  const divEl = useRef();

  useEffect(() => {
    const handler = (event) => {
      // check if the div element doesn't exist
      if (!divEl.current) {
        return;
      }

      if (!divEl.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('click', handler, true);

    return () => {
      document.removeEventListener('click', handler);
    };
  }, []);

  const handleClick = () => {
    setIsOpen(!isOpen);
  };

  const handleOptionClick = (option) => {
    setIsOpen(false);
    onChange(option);
  };

  return (
    <div className=''>
      {label && <label htmlFor="label">{label}</label>}
      {required && <span className='text-[#EB5757] ml-1'>*</span>}
      <div ref={divEl} className='relative pt-2'>
        <div role="presentation"
          className='flex items-center text-[#A6A6A8] justify-between w-full p-3 bg-white border rounded shadow cursor-pointer'
          onClick={handleClick}
        >
          {value?.label || 'Select Category'}{' '}
          {isOpen ? <GoChevronUp /> : <GoChevronDown />}
        </div>
        {isOpen && (
          <div className='absolute w-full p-3 bg-white border shadow top-full'>
            {options.map((option) => (
                <div role ="presentation"
                  className='p-1 rounded cursor-pointer hover:bg-sky-100'
                  onClick={() => handleOptionClick(option)}
                  key={option.value}
                >
                  {option.label}
                </div>
              ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default Dropdown;