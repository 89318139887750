import React from "react";
import { Box, Typography, Button } from "@mui/material";

function PriceHeader() {
  return (
    <>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          px: 3,
          background:"#17324F",
          py:1.5,
          mx:"auto",
          maxWidth:"880px"
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <img alt="price" />
          <Typography variant="subtitle1" sx={{color:"#fff", fontWeight:500}}>SEVIS FEE PRICE AND EDUCART CHARGES</Typography>
        </Box>
        <Button sx={{ background: "#E9EDF1", px:4}}>View Price</Button>
      </Box>
    </>
  );
}

export default PriceHeader;
