import { Helmet } from "react-helmet-async";
// @mui
import { Container, Stack } from "@mui/material";

// components
// import Iconify from "../components/iconify";

import { PasswordCard } from "../../sections/@dashboard/account";
// mock

// ----------------------------------------------------------------------
import Header from "./Header";
// ----------------------------------------------------------------------

export default function SecurityPage() {
  return (
    <>
      <Helmet>
        <title> Dashboard: Account: Security</title>
      </Helmet>
      <Header />
      <Container>
        <Stack sx={{ mt: 10 }} spacing={{ xs: 8, lg: 6 }}>
          <PasswordCard />
        </Stack>
      </Container>
    </>
  );
}
