import { Stack, Box, Typography } from "@mui/material";

export default function PersonalRadio() {
  return (
    <>
      <Stack
        direction="row"
        spacing={2}
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          mr: 3,
        }}
      >
        <img src="/assets/educarts/personal.png" alt="personal" />
        <Box>
          <Typography variant="h6">Personal Account </Typography>
          <Typography variant="subtitle2">
            for you, family or friends{" "}
          </Typography>
        </Box>
      </Stack>
    </>
  );
}
