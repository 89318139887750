import { Helmet } from "react-helmet-async";
// @mui
import { styled } from "@mui/material/styles";
import { Typography, Stack, Box, Grid } from "@mui/material";
// hooks
import { Link } from "react-router-dom";
// components
import Logo from "../../components/logo";
import RegisterForm3 from "../../sections/auth/register/RegisterForm3";

// ----------------------------------------------------------------------

const FormArea = styled("div")(({ theme }) => ({
  border: "1px solid #E8E8E8",
  borderRadius: "20px",
  padding: "25px ",
  maxWidth: "450px",
  boxShadow: theme.customShadows.card,
  backgroundColor: theme.palette.background.default,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
}));

// ----------------------------------------------------------------------

export default function RegisterPage3() {
  // const [num, setNum] = useState();
  // const limitChar = 6;
  // const handleChange = (e) => {
  //   if (e.target.value.toString().length <= limitChar) {
  //     setNum(e.target.value);
  //   }
  // };

  const userEmail = localStorage.getItem("userEmail");

  return (
    <>
      <Helmet>
        <title> Register | Educarts </title>
      </Helmet>

      <Grid container component="main" sx={{ height: "100vh" }}>
        <Grid
          item
          xs={12}
          md={7}
          elevation={6}
          // square
          sx={{
            mt: 8,
            mb: 4,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <FormArea
            sx={{
              maxWidth: { xs: "450px", lg: "500px", xl: "600px" },
              width: { lg: "600px" },
            }}
          >
            <Stack alignItems="center">
              <Logo sx={{ width: 150, mb: 3 }} />
              <Typography variant="h4">Confirm Code</Typography>

              <Typography
                variant="body2"
                sx={{
                  mt: 1,
                  mb: 3,
                  color: "#939697",
                  fontWeight: 500,
                  textAlign: "center",
                }}
              >
                We have sent a code to the email address you registered with
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  mb: 3,
                  color: "#626668",
                  fontWeight: 400,
                  textAlign: "center",
                }}
              >
                Enter the code sent to{" "}
                <Box component="span" sx={{ fontWeight: 700 }}>
                  {userEmail ? (
                    <span>{userEmail}.</span>
                  ) : (
                    <span>your email address.</span>
                  )}
                </Box>
              </Typography>
            </Stack>

            <RegisterForm3 />

            <Stack
              direction="column"
              alignItems="left"
              justifyContent="space-between"
              sx={{ my: 2, textAlign: "center" }}
            >
              <Typography
                sx={{ "&:hover": { textDecoration: "underline" } }}
                variant="body2"
              >
                <Link
                  to="/forgot-password"
                  style={{
                    textDecoration: "none",
                    color: "#17324f",
                    fontWeight: "700",
                  }}
                >
                  Forgot password?
                </Link>
              </Typography>
              <Typography
                variant="body2"
                sx={{ mt: 1, mb: 3, color: "#939697", fontWeight: 700 }}
              >
                Already have an account?{" "}
                <Link
                  to="/login"
                  variant="subtitle2"
                  style={{
                    textDecoration: "none",
                    color: "#17324f",
                    fontWeight: "700",
                    ml: 2,
                  }}
                  underline="hover"
                >
                  {" "}
                  Log In
                </Link>
              </Typography>
            </Stack>
          </FormArea>
        </Grid>

        <Grid
          item
          xs={false}
          md={5}
          sx={{
            backgroundImage: "url(/assets/educarts/auth_image.png)",
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        />
      </Grid>
    </>
  );
}
