// @mui
import {
  Stack,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
} from "@mui/material";

import CardRadio from "./CardRadio";
import TransferRadio from "./TransferRadio"; 

// eslint-disable-next-line react/prop-types
function Method({ handleTransfer, handleCard, card, transfer }) {
  return (
    <Stack spacing={3}>
      <FormControl
        sx={{
          alignItems: "center",
        }}
      >
        <RadioGroup row name="PaymentMethod" defaultValue="center">
          <Stack direction="column" spacing={3}>
            <FormControlLabel
              sx={{
                border: card ? "2px solid #20466f" : "#dee3e4 1px solid",
                mx: 0,
                py: 1,
                pl: 2,
                borderRadius: 1.2,
                background: card ? "#e9edf1" : "",
              }}
              value="card"
              control={
                <Radio
                  onClick={handleCard}
                  sx={{
                    "& .MuiSvgIcon-root": {},
                  }}
                />
              }
              label={<CardRadio card={card} />}
              labelPlacement="start"
            />

            <FormControlLabel
              sx={{
                border: transfer ? "2px solid #20466f" : "#dee3e4 1px solid",
                mr: 0,
                py: 1,
                pl: 2,
                borderRadius: 1.2,
                background: transfer ? "#e9edf1" : "",
              }}
              value="transfer"
              control={
                <Radio
                  onClick={handleTransfer}
                  sx={{
                    "& .MuiSvgIcon-root": {},
                  }}
                />
              }
              label={<TransferRadio transfer={transfer} />}
              labelPlacement="start"
            />
          </Stack>
        </RadioGroup>
      </FormControl>
    </Stack>
  );
}

export default Method;
