import React from "react";
import {
  Stack,
  Divider,
  Container,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import sevis from "../../assets/svgs/united.svg"


const StyledHeader = styled("div")(({ theme }) => ({
    background: "linear-gradient(270deg, #E9EDF1 0%, #E9EDF1 60%, #E9EDF1 100%)",
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    textAlign: "center",
    "&:after": {
      content: '""',
      position: "absolute",
      width: "60px",
      height: "60px",
      backgroundImage: `url("../assets/svgs/navbg.svg")`,
      // opacity: "0%",
      top: "90px",
      right: "5px",
    },
  }));

function PaymentHeader() {
    return (
        <>
        <StyledHeader>
        <Container>
          <Stack
            sx={{
              flexDirection: "row",
              alignItems: "center",
              display: "flex",
              justifyContent: "flex-start",
              gap: 0.8,
              height:"30px"
            }}
           
          >
            <img src={sevis} alt ="sevis logo"/>
            <Typography variant="h6" sx={{color:"#151a1d"}}>SEVIS FEE</Typography>
            <Divider
                orientation="vertical"
                flexItem
                sx={{ border: "1px solid #1e1e1e" }}
              />
            <Typography variant="body2">
              Complete the following information below to complete payments
            </Typography>
          </Stack>
        </Container>
      </StyledHeader>
      </>
    )
}

export default PaymentHeader
