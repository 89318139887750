import React from "react";

function Button({ selectedOption, text }) {
  return (
      <button
        type="button"
        className={`mt-4 px-10 py-4 rounded-md text-[15px] ${
          selectedOption
            ? "bg-[#17324f] cursor-pointer text-white border border-none"
            : "bg-[#17324f] cursor-not-allowed text-white border border-none opacity-60"
        }`}
        disabled={!selectedOption}
      >
        {text}{" "}
      </button>
  );
}

export default Button;
