import React from "react";
import PropTypes from "prop-types";
import { Typography, Box, Grid } from "@mui/material";
import Empty from "../../assets/svgs/Empty Box.svg";

NoTransactionHistory.propTypes = {
  heading: PropTypes.string.isRequired,
};

function NoTransactionHistory({ heading }) {
  return (
    <Grid>
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Typography
          variant="body1"
          sx={{ fontWeight: 500, color: "#000", mb: 1 }}
        >
          {heading}{" "}
        </Typography>
      </Box>

      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", lg: "row" },
          justifyContent: "center",
          py: { xs: 4, md: 2, lg: 6 },
          px: 2,
          boxShadow: 0,
          borderRadius: 0,
          background: "#fcfdfd",
          border: { xs: "1px solid #e9edf1" },
          alignItems: "center",
          gap: { xs: 4, md: 2, lg: 8 },
          textAlign: "center",
          height:{md:"415px", lg:"415px"}
        }}
      >
        <Box>
          <Typography variant="h5">No History</Typography>
          <Typography variant ="body2">You are yet to make a payment</Typography>
        </Box>
        <img
          src={Empty}
          alt="No transaction history"
        />
      </Box>
    </Grid>
  );
}

export default NoTransactionHistory;
