// @mui
import PropTypes from "prop-types";
// import { alpha, styled } from "@mui/material/styles";
import { Link } from "react-router-dom";
import { Card, Typography, Box } from "@mui/material";
// utils
// components

import Iconify from "../../../components/iconify";
// import arrow from "../../../assets/svgs/arrow.svg";

// ----------------------------------------------------------------------

// ----------------------------------------------------------------------

AppWidgetSummary.propTypes = {
  color: PropTypes.string,
  bgcolor: PropTypes.string,
  borderColor: PropTypes.string,
  icon: PropTypes.string,
  title: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
  sx: PropTypes.object,
};

export default function AppWidgetSummary({
  title,
  icon,
  color,
  borderColor,
  bgcolor,
  sx,
  link,
  ...other
}) {
  return (
    <Link to={link} style={{textDecoration:"none"}}>
    <Card
      sx={{
        py: { xs: 4, lg: 4.2 },
        px: 2,
        boxShadow: 2,
        textAlign: "center",
        borderBottom: `2px solid ${borderColor}`,
        width: { xs: "100%", lg: "50%" },
        borderRadius: 0,
        backgroundColor: bgcolor,
        cursor:"pointer",
        ...sx,
        "&:after": {
          content: '""',
          position: "absolute",
          width: "170px",
          height: "150px",
          borderRadius: "50%",
          backgroundColor: color,
          backgroundImage: `url("../../../assets/svgs/bg2.svg")`,
          opacity: "20%",
          top: "-80px",
          right: "-60px",
        },
      }}
      {...other}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
          // gap: 2,
        }}
      >
        <Box
          sx={{
            "&:after": {
              content: '""',
              position: "absolute",
              width: "150px",
              height: "150px",
              borderRadius: "50%",
              backgroundColor: color,
              backgroundImage: `url("../../../assets/svgs/bg3.svg")`,
              opacity: "20%",
              top: "-10px",
              right: "-70px",
            },
          }}
        />
        <img src={icon} alt="payment" />
        <Typography
          variant="h6"
          sx={{
            fontWeight: 500,
            mb: 1,
            color: { color },
            ml: 2,
          }}
        >
          {title}
        </Typography>
      </Box>
        <Box sx={{ 
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-end",
          mt:{lg:3}
          }}>
          <div
            style={{
              color: "white",
              backgroundColor: color,
              borderRadius: "50px",
              height: "35px",
              width: "35px",
              textAlign: "center",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              cursor: "pointer",
            }}
          >
            <Iconify icon={"ph:arrow-right"} />
          </div>
        </Box>
    </Card>
    </Link>

  );
}
