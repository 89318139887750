import { Helmet } from "react-helmet-async";
// @mui
import { styled } from "@mui/material/styles";
import { Typography, Stack, Grid } from "@mui/material";
// hooks
// components
import Logo from "../../components/logo";

// sections
import { ForgotForm } from "../../sections/auth/forgot";
// ----------------------------------------------------------------------

const FormArea = styled("div")(({ theme }) => ({
  border: "1px solid #E8E8E8",
  borderRadius: "20px",
  padding: "25px ",
  boxShadow: theme.customShadows.card,
  backgroundColor: theme.palette.background.default,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
}));

// ----------------------------------------------------------------------

export default function ForgotPassword() {

  return (
    <>
      <Helmet>
        <title> Forgot Password | Educarts </title>
      </Helmet>

      <Grid container component="main" sx={{ height: "100vh" }}>
        <Grid
          item
          xs={12}
          md={7}
          elevation={6}
          // square
          sx={{
            mt: 8,
            mb: 4,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <FormArea
            sx={{
              maxWidth: { xs: "450px", lg: "500px", xl: "600px" },
              width: { lg: "600px" },
            }}
          >
            <Stack alignItems="center">
              <Logo sx={{ width: 150, mb: 3 }} />
              <Typography variant="h4">Password Reset</Typography>

              <Typography
                variant="body2"
                sx={{ mb: 3, color: "#939697", fontWeight: 500, mt:1, textAlign:"center" }}
              >
                Don’t panic, provide the details below to recover your account
              </Typography>
              <Typography
                variant="body2"
                sx={{ mb: 3, color: "#626668", fontWeight: 500 }}
              >
                Enter your registered email below
              </Typography>
            </Stack>

            <ForgotForm />
          </FormArea>
        </Grid>

        <Grid
          item
          xs={false}
          md={5}
          sx={{
            backgroundImage: "url(/assets/educarts/auth_image.png)",
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        />
      </Grid>
    </>
  );
}
