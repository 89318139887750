import React from "react";
import {
  Card,
  Stack,
  Divider,
  Container,
  Typography,
  Box,
} from "@mui/material";
import { Link } from "react-router-dom";
import { styled } from "@mui/material/styles";
import { data } from "../sections/@dashboard/payment";
import paymentImage from "../assets/payment/payment.png";
import arrow from "../assets/payment/payment_arrow.svg";

const StyledHeader = styled("div")(({ theme }) => ({
  background: "linear-gradient(270deg, #96BEC7 0%, #B0BE9E 60%, #F1D2BA 100%)",
  paddingTop: theme.spacing(3),
  paddingBottom: theme.spacing(3),
  textAlign: "center",
  "&:after": {
    content: '""',
    position: "absolute",
    width: "60px",
    height: "60px",
    backgroundImage: `url("/assets/icons/spring.svg")`,
    opacity: "20%",
    top: "90px",
    right: "5px",
  },
}));

function PaymentPage() {
  return (
    <>
      <StyledHeader>
        <Container>
          <Stack
            sx={{
              flexDirection: "row",
              alignItems: "center",
              display: "flex",
              justifyContent: "flex-start",
              gap: 0.8,
              height: "30px",
            }}
          >
            <Typography variant="h6" sx={{ color: "#050c10" }}>
              PAYMENTS ON EDUCARTS
            </Typography>
            <Divider
              orientation="vertical"
              flexItem
              sx={{ border: "1px solid #1e1e1e" }}
            />
            <Typography variant="subtitle2" sx={{ color: "#050c10" }}>
              Select a payment to make
            </Typography>
          </Stack>
        </Container>
      </StyledHeader>
      <div className="mx-auto max-w-screen-2xl">
        <div className="flex xl:gap-5 px-4 xl:px-0 xl:pl-5">
          <div>
            <div className="grid gap-4 sm:grid-cols-2 md:grid-cols-3 pt-14 pb-14 xl:max-w-[800px] 2xl:max-w-[1500px]">
              {data.map(
                (
                  {
                    icon,
                    link,
                    title,
                    description,
                    bgcolor,
                    textColor,
                    ballColor,
                  },
                  index
                ) => (
                  <div key={index}>
                    <Card
                      sx={{
                        px: 0,
                        boxShadow: 2,
                        borderRadius: 1,
                        height: {
                          xs: "260px",
                          sm: "270px",
                          lg: "300px",
                          xl: "270px",
                        },
                        "&:after": {
                          content: '""',
                          position: "absolute",
                          width: "170px",
                          height: "150px",
                          borderRadius: "50%",
                          backgroundColor: ballColor,
                          backgroundImage: `url("../../../assets/svgs/bg2.svg")`,
                          opacity: "20%",
                          top: "-100px",
                          right: "-80px",
                          transform: "rotate(45deg)",
                        },
                      }}
                    >
                      <Box
                        sx={{
                          p: 2,
                          height: "100px",
                          boxShadow: 2,
                          backgroundColor: bgcolor,
                        }}
                      >
                        <img src={icon} alt="icon" />
                      </Box>

                      <Box>
                        <Typography
                          variant="subtitle1"
                          sx={{
                            fontWeight: 600,
                            color: "#050c10",
                            textAlign: "left",
                            px: 1,
                            my: 1,
                          }}
                        >
                          {title}
                        </Typography>
                        <Typography
                          variant="subtitle2"
                          sx={{
                            color: textColor,
                            textAlign: "left",
                            fontWeight: 500,
                            px: 1,
                          }}
                        >
                          {description}
                        </Typography>
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            "&:after": {
                              content: '""',
                              position: "absolute",
                              width: "50px",
                              height: "50px",
                              backgroundImage: `url("/assets/icons/spring.svg")`,
                              opacity: "20%",
                              bottom: "0px",
                              left: "5px",
                            },
                          }}
                        />
                      </Box>
                      {link && (
                        <Link to={link}>
                          <Box
                            sx={{
                              float: "right",
                              cursor: "pointer",
                              mr: 2,
                              mt: { xs: 2, sm: 1, md: 2 },
                            }}
                          >
                            <img src={arrow} alt="arrow" />
                          </Box>
                        </Link>
                      )}
                    </Card>
                  </div>
                )
              )}
            </div>
            <div className="relative">
              <div className="absolute bottom-0 right-0 flex gap-3">
                <h2 className="text-[#939697] text-[14px]">
                  Can't Find A Payment?
                </h2>
                <h2 className="text-[#17324f] font-[600] text-[14px]">
                  Contact Support
                </h2>
              </div>
            </div>
          </div>

          <div>
            <img
              className="hidden w-full h-full xl:block"
              src={paymentImage}
              alt="payment"
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default PaymentPage;
