import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
// @mui
import { styled, alpha } from "@mui/material/styles";
import {
  Box,
  Link,
  Button,
  Drawer,
  Typography,
  Avatar,
  Stack,
} from "@mui/material";
import axios from "axios";
// mock
import account from "../../../_mock/account";
// hooks
import useResponsive from "../../../hooks/useResponsive";
// components
import Logo from "../../../components/logo/DashboardLogo";
import Scrollbar from "../../../components/scrollbar";
import NavSection from "../../../components/nav-section";
import Iconify from "../../../components/iconify";
import Toast from "../../../components/ToastAlert";
import ButtonLoader from "../../../components/ButtonLoader";

//
import navConfig from "./config";

// ----------------------------------------------------------------------

const NAV_WIDTH = 280;

const StyledAccount = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(2, 2.5),
  border: "#E0E0E0 1px solid",
  borderRadius: Number(theme.shape.borderRadius) * 1.5,
  backgroundColor: alpha(theme.palette.grey[500], 0.12),
}));

// ----------------------------------------------------------------------

Nav.propTypes = {
  openNav: PropTypes.bool,
  onCloseNav: PropTypes.func,
};

export default function Nav({ openNav, onCloseNav }) {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const refreshToken = localStorage.getItem("userData");
  const token = localStorage.getItem("access");
  const { firstName } = JSON.parse(refreshToken);
  const { lastName } = JSON.parse(refreshToken);

  useEffect(() => {
    function refreshTokens() {
      if (localStorage.getItem("refresh")) {
        axios
          .post("https://educartapi.herokuapp.com/api/v1/token/refresh/", {
            refresh: localStorage.getItem("refresh"),

            headers: {
              "Content-Type": "application/json",
            },
          })
          .then((response) => {
            if (response) {
              localStorage.setItem("refresh", response.data.refresh);
              localStorage.setItem("access", response.data.access);
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    }
  

    const minute = 1000 * 60;
    refreshTokens();
    setInterval(refreshTokens, minute * 50);
  }, []);

  const handleClear = () => {
    window.localStorage.clear();
  };

  
const HandleLogout = () => {
    setLoading(true);
    // refreshTokens()
    axios
      .post(
        "https://educartapi.herokuapp.com/api/v1/auth/logout/",
        { refresh: localStorage.getItem("refresh") },
        {    
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          }
        
      )
      .then((response) => {
        if (response) {
          Toast.fire({
            icon: "success",
            title: `${"You have successfully logged out!"}`,
          });
          navigate("/login");
          setLoading(false);
          handleClear();
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };

  // TO HANDLE INACTIVE USERS

  document.addEventListener("mousemove", () => {
    localStorage.setItem("lastActvity", new Date());
  });
  document.addEventListener("click", () => {
    localStorage.setItem("lastActvity", new Date());
  });

  const timeInterval = setInterval(() => {
    const lastAcivity = localStorage.getItem("lastActvity");
    const diffMs = Math.abs(new Date(lastAcivity) - new Date()); // milliseconds between now & last activity
    const seconds = Math.floor(diffMs / 1000);
    const time = Math.floor(seconds / 60);
    // console.log(`${seconds} sec and ${time} min since last activity`);
    if (time === 50) {
      console.log("No activity from last 10 minutes... Logging Out");
      clearInterval(timeInterval);
      // code for logout
      HandleLogout();
    }
  }, 1000);

  const { pathname } = useLocation();

  const isDesktop = useResponsive("up", "lg");

  useEffect(() => {
    if (openNav) {
      onCloseNav();
    }
  }, [pathname]);

  const renderContent = (
    <Scrollbar
      sx={{
        height: 1,
        "& .simplebar-content": {
          height: 1,
          display: "flex",
          flexDirection: "column",
        },
      }}
    >
      <Box
        sx={{
          px: 2.5,
          py: 3,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          borderBottom: ".75px solid #E0E0E0",
        }}
      >
        <Logo sx={{ width: 150 }} />
      </Box>

      <Box sx={{ mt: 1, mb: 5, mx: 2.5 }}>
        <Link underline="none">
          <StyledAccount>
            <Avatar src={account.photoURL} alt="photoURL" />

            <Box sx={{ ml: 2 }}>
              <Typography
                variant="subtitle2"
                sx={{ color: "#fff", textTransform: "capitalize" }}
              >
                {`${firstName} ${" "}  ${lastName}`}
              </Typography>
            </Box>
          </StyledAccount>
        </Link>
      </Box>

      <NavSection data={navConfig} />

      <Box sx={{ flexGrow: 1 }} />

      <Box sx={{ px: 2.5, pb: 3, mt: 5 }}>
        <Stack
          alignItems="center"
          spacing={2}
          sx={{
            py: 2,
            border: "#6A839F 1px solid",
            borderRadius: 2,
            position: "relative",
          }}
        >
          <Box sx={{ textAlign: "center" }}>
            <Typography variant="body2" sx={{ color: "text.secondary" }}>
              Get Educarts of Mobile
            </Typography>
          </Box>

          <Button href="#" target="_blank" variant="contained">
            Install mobile app
          </Button>
        </Stack>

        <Stack
          spacing={1}
          direction="column"
          sx={{
            mt: 4,
            py: 2,
            position: "relative",
          }}
        >
          <Stack direction="row" spacing={1} sx={{ color: "#DD7076" }}>
            <Iconify icon="solar:logout-2-linear" />
            <Typography
              onClick={HandleLogout}
              sx={{ fontWeight: "fontWeightBold", cursor:"pointer" }}
            >
              {!loading ? "Logout" : <ButtonLoader />}{" "}
            </Typography>
          </Stack>
          <Stack direction="row" spacing={1} sx={{ color: "#8A8B8B" }}>
            <Iconify icon="ic:round-copyright" />
            <Typography variant="subtitle2">Copyright Educarts 2023</Typography>
          </Stack>
        </Stack>
      </Box>
    </Scrollbar>
  );

  return (
    <Box
      component="nav"
      sx={{
        flexShrink: { lg: 0 },
        width: { lg: NAV_WIDTH },
      }}
    >
      {isDesktop ? (
        <Drawer
          open
          variant="permanent"
          PaperProps={{
            sx: {
              width: NAV_WIDTH,
              bgcolor: "#0D1D2F",
              borderRightStyle: "dashed",
            },
          }}
        >
          {renderContent}
        </Drawer>
      ) : (
        <Drawer
          open={openNav}
          onClose={onCloseNav}
          ModalProps={{
            keepMounted: true,
          }}
          PaperProps={{
            sx: { width: NAV_WIDTH, bgcolor: "#0D1D2F" },
          }}
        >
          {renderContent}
        </Drawer>
      )}
    </Box>
  );
}
