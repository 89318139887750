import { Box, Typography, Avatar } from "@mui/material";

function CardHeader({text}) {
  return (
    <Box
      sx={{
        border: "2px solid #34bbff80",
        background: "#e1f6ff40",
        display: "flex",
        alignItems: "center",
        borderRadius: "5px",
        py: 2,
        gap: 2,
        px: 3,
      }}
    >
      <Avatar />
      <Typography variant="body2" sx={{ color: "#656568", fontWeight: 400 }}>
        {text}
      </Typography>
    </Box>
  );
}

export default CardHeader;
