import { useState } from "react";
import {
  // useNavigate,
  Link,
} from "react-router-dom";
// @mui
import {
  Stack,
  Typography,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import PersonalRadio from "./PersonalRadio";
import InstituteRadio from "./InstituteRadio";
// components

// ----------------------------------------------------------------------

// eslint-disable-next-line react/prop-types
export default function RegisterForm({ handleStep }) {
  return (
    <>
      <Stack spacing={3}>
        <FormControl
          sx={{
            alignItems: "center",
          }}
        >
          <RadioGroup row name="accountType" defaultValue="center">
            <Stack direction="column" spacing={3}>
              <FormControlLabel
                checked
                sx={{
                  border: "#BAC6D2 1px solid",
                  background: "#E9EDF1",
                  mx: 0,
                  py: 1,
                  pl: 2,
                  borderRadius: 1.2,
                }}
                value="personal"
                control={
                  <Radio
                    sx={{
                      "& .MuiSvgIcon-root": {},
                    }}
                  />
                }
                label={<PersonalRadio />}
                labelPlacement="start"
              />
              <FormControlLabel
                disabled
                sx={{
                  border: "#BAC6D2 1px solid",
                  background: "#E9EDF1",
                  mr: 0,
                  py: 1,
                  pl: 2,
                  borderRadius: 1.2,
                }}
                value="institute"
                control={
                  <Radio
                    sx={{
                      "& .MuiSvgIcon-root": {},
                    }}
                  />
                }
                label={<InstituteRadio />}
                labelPlacement="start"
              />
            </Stack>
          </RadioGroup>
        </FormControl>
      </Stack>

      <Stack alignItems="center">
        <LoadingButton
          sx={{ my: 2, px: 7, mt: 3, textAlign:"center" }}
          size="large"
          type="submit"
          variant="contained"
          onClick={handleStep}
        >
          Next
        </LoadingButton>
      </Stack>

      <Stack
        direction="column"
        alignItems="left"
        justifyContent="space-between"
        sx={{ my: 2, textAlign: "center" }}
      >
        <Typography
          sx={{ "&:hover": { textDecoration: "underline" } }}
          variant="body2"
        >
          <Link
            to="/forgot-password"
            style={{
              textDecoration: "none",
              color: "#17324f",
              fontWeight: "700",
            }}
          >
            Forgot password?
          </Link>
        </Typography>
        <Typography
          variant="body2"
          sx={{ mt: 1, mb: 3, color: "#939697", fontWeight: 700 }}
        >
          Already have an account?{" "}
          <Link
            to="/login"
            variant="subtitle2"
            style={{
              textDecoration: "none",
              color: "#17324f",
              fontWeight: "700",
              ml: 2,
            }}
            underline="hover"
          >
            {" "}
            Log In
          </Link>
        </Typography>
      </Stack>
    </>
  );
}
