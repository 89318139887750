import PropTypes from "prop-types";
// @mui
import {
  Box,
  Card,
  //   Link,
  Divider,
  Typography,
  Stack,
  Button,
  Input,
  FormControl,
  Avatar,
} from "@mui/material";
import { Link } from "react-router-dom";
// import { styled } from "@mui/material/styles";
import CardHeader from "./CardHeader";
// components

// ----------------------------------------------------------------------

// ----------------------------------------------------------------------

IdentificationCard.propTypes = {
  //   product: PropTypes.object,
};

export default function IdentificationCard() {
  return (
    <Card sx={{ px: { xs: 1, sm: 4 } }}>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          pb: 2,
          px: 3,
          pt: 3,
        }}
      >
        <Box>
          <Typography sx={{ fontWeight: 600 }}>Identification</Typography>
        </Box>
        {/* <Button
          sx={{
            border: "1px solid #20466f",
            color: "#20466f",
            borderRadius: "30px",
            width: 100,
            height: 35,
          }}
        >
          Edit
        </Button> */}
      </Box>
      <Divider />
      <Stack spacing={2} sx={{ p: 3 }}>
        <CardHeader
          text={
            "You need one of the following documents to complete Account Setup"
          }
        />
      </Stack>

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          mx:3,
          mt:3
        }}
      >
        <Stack spacing={4}>
          <FormControl>
            <Typography sx={{ mb: 1, color: "#151a1d", fontSize: "14px", fontWeight:600 }}>
              Passport photograph*
            </Typography>
            <Box
              alignItems="center"
              sx={{
                border: "2px dotted grey",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                borderRadius: "5px",
                py: 1,
              }}
            >
              <Avatar />
              <Typography
                variant="body2"
                sx={{color: "#939697", mt:2}}
              >
                <Link
                  to=""
                  variant="subtitle2"
                  style={{
                    textDecoration: "none",
                    color: "#17324f",
                    fontWeight: "700",
                  }}
                  underline="hover"
                >
                  {"  "}
                  Select a file{"  "}
                </Link>
                or Drag and Drop
              </Typography>
              <Typography
                variant="body2"
                sx={{color: "#939697"}}
              >
                Jpg or Png
              </Typography>
            </Box>
          </FormControl>

          <FormControl>
            <Typography sx={{ mb: 1, color: "#151a1d", fontSize: "14px", fontWeight:600 }}>
              International Passport*
            </Typography>
            <Box
              alignItems="center"
              sx={{
                border: "2px dotted grey",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                borderRadius: "5px",
                py: 1,
              }}
            >
              <Avatar />
              <Typography
                variant="body2"
                sx={{color: "#939697", mt:2}}
              >
                <Link
                  to=""
                  variant="subtitle2"
                  style={{
                    textDecoration: "none",
                    color: "#17324f",
                    fontWeight: "700",
                  }}
                  underline="hover"
                >
                  {"  "}
                  Select a file{"  "}
                </Link>
                or Drag and Drop
              </Typography>
              <Typography
                variant="body2"
                sx={{color: "#939697"}}
              >
                Jpg or Png
              </Typography>
            </Box>
          </FormControl>


          <FormControl>
            <Typography sx={{ mb: 1, color: "#151a1d", fontSize: "14px", fontWeight:600 }}>
              National ID*
            </Typography>
            <Box
              alignItems="center"
              sx={{
                border: "2px dotted grey",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                borderRadius: "5px",
                py: 1,
              }}
            >
              <Avatar />
              <Typography
                variant="body2"
                sx={{color: "#939697", mt:2}}
              >
                <Link
                  to=""
                  variant="subtitle2"
                  style={{
                    textDecoration: "none",
                    color: "#17324f",
                    fontWeight: "700",
                  }}
                  underline="hover"
                >
                  {"  "}
                  Select a file{"  "}
                </Link>
                or Drag and Drop
              </Typography>
              <Typography
                variant="body2"
                sx={{color: "#939697"}}
              >
                Jpg or Png
              </Typography>
            </Box>
          </FormControl>



        </Stack>
      </Box>
    </Card>
  );
}
