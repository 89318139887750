import { Box, Typography, Avatar, Grid, Divider } from "@mui/material";

function PasswordTop() {
  return (
    <Box
      sx={{
        mt: 1,
        mb: 4,
      }}
    >
      <Grid
        alignItems="flex-start"
        container
        sx={{
          background: "#17324f",
          borderRadius: "5px",
          textAlign: "center",
          py: 2,
          gap: 2,
        }}
      >
        <Grid
          xs={6}
          md={3}
          item
          alignItems="center"
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            color: "#fff",
          }}
        >
          <Avatar />
          <Typography
            sx={{ textDecoration: "capitalize" }}
            variant="body2"
            sx={{ fontWeight: 400, pt: 1 }}
          >
            Create a strong password{" "}
          </Typography>
        </Grid>
        <Divider orientation="vertical" flexItem />
        <Grid
          xs={4}
          md={2.5}
          item
          alignItems="center"
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            color: "#fff",
          }}
        >
          <Avatar />
          <Typography variant="subtitle2" sx={{ fontWeight: 400, pt: 1 }}>
            Use a minimum of 8 characters when choosing a new password{" "}
          </Typography>
        </Grid>
        <Divider
          sx={{ display: { xs: "none", lg: "block" } }}
          orientation="vertical"
          flexItem
        />
        <Grid
          xs={6}
          md={2.5}
          item
          alignItems="center"
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            color: "#fff",
          }}
        >
          <Avatar />
          <Typography variant="subtitle2" sx={{ fontWeight: 400, pt: 1 }}>
            Avoid using personal details like name and birthday e.g Tolu2005{" "}
          </Typography>
        </Grid>
        <Divider orientation="vertical" flexItem />
        <Grid
          xs={4}
          md={2.5}
          item
          alignItems="center"
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            color: "#fff",
          }}
        >
          <Avatar />
          <Typography variant="subtitle2" sx={{ fontWeight: 400, pt: 1 }}>
            Use uppercase & lowercase letters and symbols e.g 5MagP@$${" "}
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
}

export default PasswordTop;
