import { useState, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
// @mui
import {
  Stack,
  TextField,
  Typography,
  FormHelperText,
  Box,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
// components
import Toast from "../../../components/ToastAlert";
import ButtonLoader from "../../../components/ButtonLoader";
import axiosClient from "../../../axios-client";
// ----------------------------------------------------------------------

export default function ForgotForm() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [disabledButton, setDisabledButton] = useState(false);

  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: Yup.object({
      email: Yup.string().email("Invalid email address").required("Required"),
    }),
    validateOnChange: true,

    onSubmit: (values) => {
      setDisabledButton(true);
      setLoading(true);
      axiosClient
        .post(
          "/v1/auth/forgot-password/",
          values
        )
        .then((response) => {
          if (response) {
            Toast.fire({
              icon: "success",
              title: `${response.data.message}`,
            });
            navigate("/get-otp");
            setLoading(false);
            setDisabledButton(false);
          }
          localStorage.setItem("email", response.data.data.email);
          localStorage.setItem("id", response.data.data.id);
        })
        .catch((err) => {
          setLoading(false);
          setDisabledButton(false);

          if (err.response) {
            const error = err.response.data.errorMessage;
            let errMessage = "";
            // eslint-disable-next-line no-restricted-syntax
            for (const key in error) {
              if (Object.hasOwnProperty.call(error, key)) {
                const element = error[key];
                // eslint-disable-next-line no-const-assign
                errMessage = element.message;
              }
            }
            Toast.fire({
              icon: "error",
              title: `${errMessage || err.response.statusText}`,
            });
          } else if (err.message) {
            Toast.fire({
              icon: "error",
              title: `${err.message}`,
            });
          } else {
            Toast.fire({
              icon: "error",
              title: `${err.message}`,
            });
          }
        });
    },
  });

  useEffect(() => {
    if (!(formik.isValid && formik.dirty)) {
      setDisabledButton(true);
    } else {
      setDisabledButton(false);
    }
  }, [formik.isValid, formik.dirty]);

  return (
    <>
      <Stack>
        <TextField
          name="email"
          label="Email address"
          value={formik.values.email}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          fullWidth
        />
        {formik.errors.email ? (
          <FormHelperText error> {formik.errors.email}</FormHelperText>
        ) : null}
      </Stack>

      <Stack alignItems="center">
        <LoadingButton
          sx={{ my: 2, px: 7, mt: 3 }}
          size="large"
          type="submit"
          variant="contained"
          disabled={disabledButton}
          onClick={formik.handleSubmit}
        >
          {!loading ? (
            "Verify"
          ) : (
            <Box sx={{ alignItems: "center" }}>
              <ButtonLoader />
            </Box>
          )}
        </LoadingButton>
      </Stack>

      <Stack
        direction="column"
        alignItems="left"
        justifyContent="space-between"
        sx={{ my: 2, textAlign: "center" }}
      >
        <Typography
          variant="body2"
          sx={{ mt: 1, mb: 3, color: "#939697", fontWeight: 700 }}
        >
          Don’t have an Account?{" "}
          <Link
            to="/register"
            variant="subtitle2"
            style={{
              textDecoration: "none",
              color: "#17324f",
              fontWeight: "700",
            }}
            underline="hover"
          >
            {" "}
            Sign Up
          </Link>
        </Typography>
      </Stack>
    </>
  );
}
