import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
// @mui
import {
  Stack,
  TextField,
  FormHelperText,
  IconButton,
  InputAdornment,
  Box,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
// components
import Toast from "../../../components/ToastAlert";
import ButtonLoader from "../../../components/ButtonLoader";
import Iconify from "../../../components/iconify";
import axiosClient from "../../../axios-client";
// ----------------------------------------------------------------------

export default function CreatePasswordForm() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [disabledButton, setDisabledButton] = useState(false);
  const userId = localStorage.getItem("id");
  const [showPassword, setShowPassword] = useState(false);

  const formik = useFormik({
    initialValues: {
      confirm_password: "",
      password: "",
      id: userId,
    },
    validationSchema: Yup.object({
      password: Yup.string()
        .required("Password is required")
        .min(8, "Password is too short - should be 8 characters or longer"),
      confirm_password: Yup.string().test(
        "passwords-match",
        "Passwords must match",
        // eslint-disable-next-line func-names
        function (value) {
          // eslint-disable-next-line react/no-this-in-sfc
          return this.parent.password === value;
        }
      ),
    }),

    validateOnChange: true,

    onSubmit: (values) => {
      setLoading(true);
      setDisabledButton(true);

      axiosClient
        .put(
          "/v1/auth/reset-password/",
          values
        )
        .then((response) => {
          if (response) {
            Toast.fire({
              icon: "success",
              title: `${response.data.message}`,
            });
            navigate("/login");
            setLoading(false);
            setDisabledButton(false);
          }
        })
        .catch((err) => {
          setLoading(false);
          setDisabledButton(false);
          if (err.response) {
            const error = err.response.data.errorMessage;
            let errMessage = "";
            // eslint-disable-next-line no-restricted-syntax
            for (const key in error) {
              if (Object.hasOwnProperty.call(error, key)) {
                const element = error[key];
                // eslint-disable-next-line no-const-assign
                errMessage = element.message;
              }
            }
            Toast.fire({
              icon: "error",
              title: `${errMessage || err.response.statusText}`,
            });
          } else if (err.message) {
            Toast.fire({
              icon: "error",
              title: `${err.message}`,
            });
          } else {
            Toast.fire({
              icon: "error",
              title: `${err.message}`,
            });
          }
        });
    },
  });

  useEffect(() => {
    if (!(formik.isValid && formik.dirty)) {
      setDisabledButton(true);
    } else {
      setDisabledButton(false);
    }
  }, [formik.isValid, formik.dirty]);

  return (
    <>
      <Stack sx={{width:"100%"}} spacing={3}>
        <Box>
          <TextField
            name="password"
            label="Password"
            value={formik.values.password}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            type={showPassword ? "text" : "password"}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={() => setShowPassword(!showPassword)}
                    edge="end"
                  >
                    <Iconify
                      icon={showPassword ? "eva:eye-fill" : "eva:eye-off-fill"}
                    />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          {formik.errors.password && formik.touched.password ? (
            <FormHelperText error>{formik.errors.password}</FormHelperText>
          ) : null}
        </Box>
        <Box>
          <TextField
            name="confirm_password"
            label="Confirm Password"
            value={formik.values.confirm_password}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            type={showPassword ? "text" : "password"}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={() => setShowPassword(!showPassword)}
                    edge="end"
                  >
                    <Iconify
                      icon={showPassword ? "eva:eye-fill" : "eva:eye-off-fill"}
                    />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          {formik.errors.confirm_password && formik.touched.confirm_password ? (
            <FormHelperText error>
              {formik.errors.confirm_password}
            </FormHelperText>
          ) : null}
        </Box>
      </Stack>

      <Stack alignItems="center">
        <LoadingButton
          sx={{ my: 2, px: 7, mt: {xs:5, md:8} }}
          size="large"
          type="submit"
          variant="contained"
          disabled={disabledButton}
          onClick={formik.handleSubmit}
        >
          {!loading ? (
            "Create Password"
          ) : (
            <Box sx={{ alignItems: "center" }}>
              <ButtonLoader />
            </Box>
          )}
        </LoadingButton>
      </Stack>
    </>
  );
}
