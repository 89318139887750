import React from "react";
import { Grid, Divider, Typography, Box } from "@mui/material";
import bg1 from "../../assets/svgs/dash1.svg";
import bg2 from "../../assets/svgs/dash2.svg";
import bg3 from "../../assets/svgs/dash3.svg";
import bg4 from "../../assets/svgs/dash4.svg";

function TopBar() {
  return (
    <Box>
      <Grid spacing={0} sx={{ background: "#000", pr: 2, mb: 3 }} container>
        <Grid item xs={3} lg={6}>
          <Box sx={{ display: "flex", backgroundColor: "black" }}>
            <img src={bg1} alt="dashboard topbar pic" />
            <img src={bg2} alt="dashboard topbar pic" />
            <img src={bg3} alt="dashboard topbar pic" />
          </Box>
        </Grid>

        <Grid
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
          item
          xs={3}
          lg={3}
        >
          <img style={{ marginLeft: 3 }} src={bg4} alt="dashboard topbar" />

          <Divider
            orientation="vertical"
            sx={{
              display: { xs: "none", sm: "flex" },
              backgroundColor: "gray",
              my: 2,
              ml: 2,
            }}
            flexItem
          />
        </Grid>

        <Grid
          item
          xs={3}
          lg={2}
          sx={{
            color: "#fff",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Typography
            variant="body2"
            sx={{
              textDecoration: "underline",
              textAlign: "center",
              color: "#fff",
            }}
          >
            Contact Us Now
          </Typography>
        </Grid>
        <Grid item lg={1}>
          <Typography variant="caption" sx={{ color: "#fff" }}>
            Sponsored Ad
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
}

export default TopBar;
