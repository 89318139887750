import React from "react";
import { Typography, Container, Box, Button } from "@mui/material";
import copy from "../../../assets/svgs/copy.svg";

// eslint-disable-next-line react/prop-types
function TopBar({ transactions }) {
  let orderType;
  let status;
  // eslint-disable-next-line array-callback-return, react/prop-types
  transactions.map((transaction) => {
    orderType = transaction.order_type;
    // eslint-disable-next-line prefer-destructuring
    status = transaction.status;
  });
  return (
    <>
      <Container
        maxWidth="sm"
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          gap: 2,
          borderRight: "1px solid #dee3e4",
          borderLeft: "1px solid #dee3e4",
          borderBottom: "1px solid #dee3e4",
          py: 2,
          borderRadius: 1,
          textAlign: "center",
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
          {" "}
          <Typography variant="body2" sx={{ color: "#626668" }}>
            Payment ID:
          </Typography>
          <Typography
            variant="body2"
            sx={{ color: "#151a1d", fontWeight: 600 }}
          >
            {orderType}
          </Typography>
          <Typography variant="body2" sx={{cursor:"pointer", color: "#626668" }}>
            <img src={copy} alt="copy" />
          </Typography>
        </Box>

        <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
          <Typography variant="body2" sx={{ color: "#626668" }}>
            Payment Status:
          </Typography>
          <Button
            sx={{ background: "#fbf2e6", width: "100px", color: "#db8000" }}
          >
            {status}{" "}
          </Button>{" "}
        </Box>
      </Container>
    </>
  );
}

export default TopBar;
