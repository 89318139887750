// import { useState, useEffect } from "react";
// import { useNavigate, Link } from "react-router-dom";

// @mui
import {
  Typography,
  Box,
  Stack,
  //   IconButton,
  //   InputAdornment,
  TextField,
  FormHelperText,
  InputLabel,
} from "@mui/material";

import { useFormik } from "formik";
import * as Yup from "yup";

// import { LoadingButton } from "@mui/lab";
// components
// import Iconify from "../../../components/iconify";
// import Toast from "../../../components/ToastAlert";
// import ButtonLoader from "../../../components/ButtonLoader";
import axiosClient from "../../../axios-client";
import verve from "../../../assets/svgs/image 48.svg";
import visa from "../../../assets/svgs/image 42.svg";
import paypal from "../../../assets/svgs/image 43.svg";

function Card() {
  //   const navigate = useNavigate();
  //   const [loading, setLoading] = useState(false);
  //   const [disabledButton, setDisabledButton] = useState(false);

  const formik = useFormik({
    initialValues: {
      // first_name: "",
      // last_name: "",
      // email: "",
    },
    validationSchema: Yup.object({
      email: Yup.string().email("Invalid email address").required("Required!"),
    }),

    validateOnChange: true,

    onSubmit: (values) => {
      axiosClient
        .post("/v1/auth/register/", values)
        .then((response) => {
          if (response) {
            //   Toast.fire({
            //     icon: "success",
            //     title: `${response.data.message.success}`,
            //   });
            // navigate("/signup-confirm-code");
          }

          localStorage.setItem("userId", response.data.data.id);
          localStorage.setItem("userEmail", response.data.data.email);
        })
        .catch((err) => {
          if (err.response) {
            const error = err.response.data.errorMessage;
            let errMessage = "";
            // eslint-disable-next-line no-restricted-syntax
            for (const key in error) {
              if (Object.hasOwnProperty.call(error, key)) {
                const element = error[key];
                // eslint-disable-next-line no-const-assign
                errMessage = element;
              }
            }
            //   Toast.fire({
            //     icon: "error",
            //     title: `${errMessage || err.response.statusText}`,
            //   });
          } else {
            //   Toast.fire({
            //     icon: "error",
            //     title: `${err.message || err.response.statusText}`,
            //   });
          }
        });
    },
  });

  // useEffect(() => {
  //   if (!(formik.isValid && formik.dirty)) {
  //     setDisabledButton(true);
  //   } else {
  //     setDisabledButton(false);
  //   }
  // }, [formik.isValid, formik.dirty]);
  return (
    <>
      <Typography variant="subtitle1" sx={{ mb: 2, color: "#626668" }}>
        Enter your card details
      </Typography>
      <Box
        sx={{
          py: 2,
          my: 2,
          display: "flex",
          alignItems: "center",
          gap: 1,
          justifyContent: "center",
          border: "2px solid #34bbff",
          background: "#e1f6ff",
          borderRadius: 1,
          mb: 2,
        }}
      >
        <Typography
          variant="subtitle2"
          sx={{ px: { xs: 2, md: 0 }, color: "#939697" }}
        >
          We accept the following cards
        </Typography>
        <img src={verve} alt="verve" />
        <img src={visa} alt="visa" />
        <img src={paypal} alt="paypal" />
      </Box>

      <Stack spacing={3}>
        <Box>
          <InputLabel sx={{ mb: 1, color: "#17324f", fontWeight: 500 }}>
            Card Name <span style={{ color: "red" }}>*</span>
          </InputLabel>
          <TextField
            name="card_name"
            label="Enter Card Name"
            value={formik.values.card_name}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            fullWidth
          />
          {formik.errors.card_name && formik.touched.card_name ? (
            <FormHelperText error> {formik.errors.card_name}</FormHelperText>
          ) : null}
        </Box>

        <Box>
          <InputLabel sx={{ mb: 1, color: "#17324f", fontWeight: 500 }}>
            Card Number <span style={{ color: "red" }}>*</span>
          </InputLabel>
          <TextField
            name="card_name"
            label="0000 0000 0000"
            value={formik.values.card_number}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            fullWidth
          />
          {formik.errors.card_number && formik.touched.card_number ? (
            <FormHelperText error> {formik.errors.card_number}</FormHelperText>
          ) : null}
        </Box>

        <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
          <Box>
            <InputLabel sx={{ mb: 1, color: "#17324f", fontWeight: 500 }}>
              Expiry Date<span style={{ color: "red" }}>*</span>
            </InputLabel>
            <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
              <TextField
                name="Month"
                label="Month"
                value={formik.values.date}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                fullWidth
              />
              <TextField
                name="Year"
                label="Year"
                value={formik.values.date}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                fullWidth
              />
            </Box>
          </Box>
          <Box>
            <InputLabel sx={{ mb: 1, color: "#17324f", fontWeight: 500 }}>
              CVV<span style={{ color: "red" }}>*</span>
            </InputLabel>
            <TextField
              name="Month"
              label="***"
              value={formik.values.cvv}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              fullWidth
            />
          </Box>
        </Box>
      </Stack>
    </>
  );
}

export default Card;
