import React from "react";
import PropTypes from "prop-types";
import { Typography, Box, Grid } from "@mui/material";
import Recent from "../../assets/svgs/profile.svg";

NoRecentPayment.propTypes = {
  heading: PropTypes.string.isRequired,
};

function NoRecentPayment({ heading }) {
  return (
    <Grid sx={{ mt: { xs: 4, md: 1, lg: 5 } }}>
      <Box>
        <Typography
          variant="body1"
          sx={{ fontWeight: 500, color: "#000", mb: 1 }}
        >
          {heading}{" "}
        </Typography>
      </Box>

      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", sm: "row" },
          justifyContent: "center",
          py: { xs: 6, md: 2, lg: 6 },
          px: 2,
          boxShadow: 0,
          borderRadius: 0,
          background:"#fcfdfd",
          border: { xs: "1px solid #e9edf1" },
          alignItems: "center",
          gap: { xs: 4, md: 2, lg: 8 },
          textAlign: "center",
          height:{md:"240px", lg:"175px"}
        }}
      >
        <img src={Recent} alt="No payment history" />

        <Box>
          <Typography variant="h5">No Recent Payments</Typography>
          <Typography variant="body2">Your recent payment will appear here</Typography>
        </Box>
      </Box>
    </Grid>
  );
}

export default NoRecentPayment;
