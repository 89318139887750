import { useState, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
// @mui
import {
  Stack,
  TextField,
  Typography,
  FormHelperText,
  Box,
  IconButton,
  InputAdornment,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
// components
import Iconify from "../../../components/iconify";
import Toast from "../../../components/ToastAlert";
import ButtonLoader from "../../../components/ButtonLoader";
import axiosClient from "../../../axios-client";
// ----------------------------------------------------------------------

export default function OtpForm() {
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loading2, setLoading2] = useState(false);
  const [disabledButton, setDisabledButton] = useState(false);
  const userId = localStorage.getItem("id");
  const userData = {
    id: userId,
  };
  const handleResendOtp = () => {
    setLoading2(true);
    axiosClient
      .post(
        "/v1/auth/regenerate-otp/",
        userData
      )
      .then((response) => {
        if (response) {
          setLoading2(false);
          Toast.fire({
            icon: "success",
            title: `${response.data.message}`,
          });
        }
      })
      .catch((err) => {
        setLoading2(false);
        if (err.response) {
          setLoading2(false);
          const error = err.response.data.errorMessage;
          let errMessage = "";
          // eslint-disable-next-line no-restricted-syntax
          for (const key in error) {
            if (Object.hasOwnProperty.call(error, key)) {
              const element = error[key];
              // eslint-disable-next-line no-const-assign
              errMessage = element.message;
            }
          }

          Toast.fire({
            icon: "error",
            title: `${errMessage || err.response.statusText}`,
          });
          setLoading2(false);
        } else {
          Toast.fire({
            icon: "error",
            title: `${err.message || err.response.statusText}`,
          });
        }
      });
  };


  const formik = useFormik({
    initialValues: {
      otp: "",
      id: userId,
    },

    validationSchema: Yup.object({
      otp: Yup.string()
        .required("OTP is required")
        .min(4, "OTP is not complete yet")
        .max(4, "Ensure this field has no more than 4 characters"),
    }),

    validateOnChange:true,

    onSubmit: (values) => {
      setLoading(true);
      setDisabledButton(true);

      axiosClient
        .post(
          "/v1/auth/forgot-password/verify-otp/",
          values
        )
        .then((response) => {
          if (response) {
            Toast.fire({
              icon: "success",
              title: `${response.data.message}`,
            });
            navigate("/reset-password");
            setLoading(false);
            setDisabledButton(false);
          }
        })
        .catch((err) => {
          setLoading(false);
          setDisabledButton(false);
          console.log(err)
          if (err.response) {
            const error = err.response.data.errorMessage;
            let errMessage = "";
            // eslint-disable-next-line no-restricted-syntax
            for (const key in error) {
              if (Object.hasOwnProperty.call(error, key)) {
                const element = error[key];
                // eslint-disable-next-line no-const-assign
                errMessage = element.message;
              }
            }
            Toast.fire({
              icon: "error",
              title: `${errMessage || err.response.statusText}`,
            });
          } else if (err.message) {
            Toast.fire({
              icon: "error",
              title: `${err.message}`,
            });
          } else {
            Toast.fire({
              icon: "error",
              title: `${err.message}`,
            });
          }
        });
    },
  });
  
  useEffect(() => {
    if(!(formik.isValid && formik.dirty)){
     setDisabledButton(true)
   }else{
     setDisabledButton(false)
   }
  }, [formik.isValid, formik.dirty]);

  return (
    <>
      <Stack>
        <TextField
          name="otp"
          value={formik.values.otp}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          fullWidth
          type={showPassword ? "number" : "password"}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  onClick={() => setShowPassword(!showPassword)}
                  edge="end"
                >
                  <Iconify
                    icon={showPassword ? "eva:eye-fill" : "eva:eye-off-fill"}
                  />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />

        {formik.errors.otp && formik.touched.otp ? (
          <FormHelperText error> {formik.errors.otp}</FormHelperText>
        ) : null}
      </Stack>

      <Stack
        direction="row"
        alignItems="left"
        justifyContent="center"
        sx={{ mt: 2, textAlign: "center", gap: 0.5 }}
      >
        <Typography
          variant="body2"
          sx={{
            color: "#939697",
            fontWeight: 700,
            textDecoration: "underline",
            cursor: "pointer",
          }}
          onClick={handleResendOtp}
        >
          {!loading2 ? (
            "Resend Code"
          ) : (
              <Box sx={{background:"#939697", px:2, py:1}}>
              <ButtonLoader />
              </Box>
          )}{" "}
        </Typography>
        <Typography
          sx={{
            fontWeight: 700,
          }}
        >
          <Link variant="subtitle2" textDecoration="none">
            {"  "}
            00:24
          </Link>
        </Typography>
      </Stack>

      <Stack alignItems="center">
        <LoadingButton
          sx={{ my: 2, px: 7, mt: 3 }}
          size="large"
          type="submit"
          variant="contained"
          disabled={disabledButton}
          onClick={formik.handleSubmit}
        >
          {!loading ? (
            "Verify"
          ) : (
            <Box sx={{ alignItems: "center" }}>
              <ButtonLoader />
            </Box>
          )}
        </LoadingButton>
      </Stack>

      <Stack
        direction="column"
        alignItems="left"
        justifyContent="space-between"
        sx={{ my: 2, textAlign: "center" }}
      >
        <Typography
          variant="body2"
          sx={{ mt: 1, mb: 3, color: "#939697", fontWeight: 700 }}
        >
          Don’t have an Account?{" "}
          <Link
            to="/register"
            variant="subtitle2"
            style={{
              textDecoration: "none",
              color: "#17324f",
              fontWeight: "700",
            }}
            underline="hover"
          >
            {" "}
            Sign Up
          </Link>
        </Typography>
      </Stack>
    </>
  );
}
