import sevis from "../../../assets/payment/payment_1.svg";
import track from "../../../assets/payment/payment_2.svg";
import wes from "../../../assets/payment/payment_3.svg";
import tuition from "../../../assets/payment/payment_4.svg";
import application from "../../../assets/payment/payment_5.svg";
import consult from "../../../assets/payment/payment_6.svg";

export const data = [
  {
    icon: sevis,
    link: "/payments/sevis",
    title: "SEVIS Fee",
    description:
      "Make Application payments with ease, with our robust payments solutions, we do the  stress for you, so you worry less",
    bgcolor: "#CFDEF2",
    textColor: "#666969",
    ballColor:'#95A2B1'
  },
  {
    icon: consult,
    link: "/consultation",
    title: "Consultation",
    description:
      "Make Application payments with ease, with our robust payments solutions, we do the  stress for you, so you worry less",
    bgcolor: "#F1E6F4",
    textColor: "#666969",
    ballColor:'#DC84F4'
  },
  {
    icon: track,
    link: "/payments/track",
    title: "Track Payments",
    description:
      "Make Application payments with ease, with our robust payments solutions, we do the  stress for you, so you worry less",
    bgcolor: "#FAEAEB",
    textColor: "#666969",
    ballColor:'#9EFBDC0'
  },

  {
    icon: tuition,
    title: "Tuition Fee",
    description: "Coming Soon!!!",
    bgcolor: "#e9f7f0",
    textColor: "#939697",
    ballColor:"#68C996"
  },
  {
    icon: application,
    title: "Application Fee",
    description: "Coming Soon!!!",
    bgcolor: "#e9f7f0",
    textColor: "#939697",
    ballColor:"#68C996"
  },

  {
    icon: wes,
    title: "WES Evaluation",
    description: "Coming Soon!!!",
    bgcolor: "#e9f7f0",
    textColor: "#939697",
    ballColor:"#68C996"
  },
];
