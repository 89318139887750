import React, { useState } from "react";
import { Helmet } from "react-helmet-async";
import { Link } from "react-router-dom";
import { Typography, Box } from "@mui/material";
import { ReactComponent as Val } from "../../assets/svgs/sevis.svg";
import { ReactComponent as Excl } from "../../assets/svgs/cred.svg";
import Button from "./Button";


const options = [
  {
    id: 1,
    title: "Carry Out All Payment For Me",
    description: "We handle all procedures and payments on your behalf",
    link: "/payments/sevis/form",
    disable:false
  },
  {
    id: 1,
    title: "I Have My SEVIS FEE Payment Coupon",
    description: "With your coupon, we complete your payments",
    link: "#",
    disable:true
  },
];

function Sevis() {
  const [selectedOption, setSelectedOption] = useState(null);

  return (
    <>
      <Helmet>
        <title>Payments | Sevis Fee</title>
        <meta name="description" content="Pay Tuition Fees Abroad" />
        <link rel="canonical" href="/payments/sevis" />
      </Helmet>

      <div className="mx-auto max-w-screen-2xl">
        <div className="bg-[#E9EDF1]">
          <div className="flex items-center gap-2 md:px-32">
            <Val className="w-14" />
            <h3 className="text-[#050C10] text-[18px] md:text-[24px] font-bold">
              Get started with SEVIS FEE
            </h3>
          </div>
        </div>

        <div className="flex flex-col md:flex-row gap-6 md:px-8 py-14">
          <div className="bg-[#FFF] mx-4 shadow-lg p-6 justify-center text-[16px]">
            <div className="flex flex-col gap-4 md:flex-row md:items-center">
              <Excl className="w-14" />
              <p className="max-w-[350px]">
                The SEVIS Fee is a fee paid by prospective immigrant seeking
                certain visa status
              </p>
            </div>
            <ul className="list-outside list-disc max-w-[380px] mt-6 md:ml-24">
              <li>
                Ensure you have accurately completed the Form 1-20 or DS-2019,
                If you do not have any of these forms, contact your school
                official or program sponsor
              </li>
              <li className="mt-6">
                If you have generated the SEVIS Payment coupon you will need to
                upload the document together with your passport.
              </li>
            </ul>
          </div>

          <div>
            <div className="px-4">
              <h4 className="text-[20px] leading-10 text-[#151A1D]">
                Choose one option below to get started
              </h4>
              {options.map((option, index) => (
                <Box
                  key={index}
              sx={{
                    display: "flex",
                    cursor: "pointer",
                    borderRadius: 1,
                    color: "#44484a",
                    px: 4,
                    py: 2,
                    my: 3,
                    height: "100px",
                    justifyContent: "space-between",
                    opacity: option.disable ? 0.5 : 1,
                    border: selectedOption
                      ? "1px solid #34BBFF"
                      : "1px solid grey",
                    background: selectedOption ? "#E1F6FF" : "#FFF",
                  }}
                  onClick={() => setSelectedOption(option)}
                >
                  <div>
                    <Typography variant="subtitle1">{option.title}</Typography>
                    <Typography variant="caption">
                      {option.description}
                    </Typography>
                  </div>

                  <div>
                    <input
                      type="radio"
                      name={option.id}
                      id={option.id}
                      className={`${option.disable} ? "disabled" : ""` }
                    
                    />
                  </div>
                </Box>
              ))}

              <Link to={selectedOption ? selectedOption.link : ""}>
                <div className="text-center">
                  <Button
                    text={"Get started"}
                    selectedOption={selectedOption}
                  />
                </div>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Sevis;
