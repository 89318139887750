import React from "react";
import { Link } from "react-router-dom";
import {
  Stack,
  Typography,
  Divider,
  Box,
  // Checkbox,
  Button,
} from "@mui/material";
import FileUploader from "./FileUploader";
import lock from "../../../assets/svgs/lock.svg";

// eslint-disable-next-line react/prop-types
function OrderSummary({ transactions, card, transfer }) {
  let orderType;
  let totalAmount;
  // eslint-disable-next-line array-callback-return, react/prop-types
  transactions.map((transaction) => {
    orderType = transaction.order_type;
    totalAmount = transaction.amount;
  });

  const Pay = () => (
    <>
      <Box sx={{display:"flex", alignItems:"center", justifyContent:"center", mt:2, mb:3, gap:1}}>
        <img src={lock} alt="lock"/>
        <Typography variant="caption" sx={{ textAlign: "center" }}>
        Secure Payment
      </Typography>
      </Box>

      <Box>
        {/* <Checkbox name="acceptedTerms" /> */}

        <Typography
          sx={{
            textAlign: "center",
            color: "#20466f",
            ml: 2,
            fontSize: "15px",
          }}
        >
          {" "}
          I have read, understand, and agree to the Educarts{" "}
          <Link
            to="/terms-and-conditions"
            style={{ textDecoration: "underline", cursor: "pointer" }}
          >
            Terms of Use
          </Link>{" "}
          and{" "}
          <Link
            to="/privacy-policy"
            style={{ textDecoration: "underline", cursor: "pointer" }}
          >
            Privacy Policy
          </Link>
        </Typography>
      </Box>

      <Stack alignItems="center">
        <Button
          sx={{ textAlign: "center", my: 2, mt: 3 }}
          size="medium"
          type="submit"
          variant="contained"
        >
          Pay ${totalAmount}
        </Button>
      </Stack>
    </>
  );

  const Confirm = () => (
    <>
      <Typography variant="subtitle1" sx={{ textAlign: "center", my: 3, color:"#626668" }}>
        Upload your payments reciepts to continue{" "}
      </Typography>

      <Box>
        <Typography variant="subtitle2" sx={{ color: "#17324f", mb: 1 }}>
          Payments Reciepts <span style={{ color: "red" }}>*</span>
        </Typography>
        <FileUploader />
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Typography variant="caption" sx={{ display: "none" }}>
            This
          </Typography>
          <Typography variant="caption">Maximum Size: 5mb</Typography>
        </Box>
      </Box>

      <Box
        sx={{
          mt: 5,
        }}
      >
        {/* <Checkbox name="acceptedTerms" /> */}

        <Typography
          sx={{
            textAlign: "center",
            color: "#20466f",
            ml: 2,
            fontSize: "15px",
          }}
        >
          {" "}
          I have read, understand, and agree to the Educarts{" "}
          <Link
            to="/terms-and-conditions"
            style={{ textDecoration: "underline", cursor: "pointer" }}
          >
            Terms of Use
          </Link>{" "}
          and{" "}
          <Link
            to="/privacy-policy"
            style={{ textDecoration: "underline", cursor: "pointer" }}
          >
            Privacy Policy
          </Link>
        </Typography>
      </Box>

      <Stack alignItems="center">
        <Button
          sx={{ textAlign: "center", my: 2, mt: 3 }}
          size="large"
          type="submit"
          variant="contained"
        >
          Confirm Payments{" "}
        </Button>
      </Stack>
    </>
  );

  return (
    <Stack
      sx={{
        display: "flex",
        flexDirection: "column",
        color: "#626668",
      }}
    >
      <Typography variant="caption" sx={{ textAlign: "center", pt: 2 }}>
        Total amount
      </Typography>
      <Typography
        variant="h5"
        sx={{ textAlign: "center", color: "#151a1d", mb: 2 }}
      >
        $ {totalAmount}{" "}
      </Typography>
      <Divider />
      <Typography variant="caption" sx={{ textAlign: "left", mt: 2 }}>
        You are paying for
      </Typography>
      <Box sx={{ display: "flex", justifyContent: "space-between", py: 1 }}>
        <Typography variant="subtitle2">{orderType}</Typography>
        <Typography variant="subtitle2">$ 140.00</Typography>
      </Box>
      <Box sx={{ display: "flex", justifyContent: "space-between", py: 1 }}>
        <Typography variant="subtitle2">Educart Charges</Typography>
        <Typography variant="subtitle2">$ 20.00</Typography>
      </Box>
      <Divider />
      <Box sx={{ display: "flex", justifyContent: "space-between", py: 2 }}>
        <Typography variant="subtitle2">Total</Typography>
        <Typography variant="subtitle2">$ 160.00</Typography>
      </Box>
      <Divider />

      {card && <Pay />}
      {transfer && <Confirm />}
    </Stack>
  );
}

export default OrderSummary;
