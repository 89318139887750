import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import axios from "axios";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Checkbox from "@mui/material/Checkbox";
import { visuallyHidden } from "@mui/utils";
import payment1 from "../../assets/svgs/Payment.svg";
// import payment2 from "../../assets/svgs/Payment (1).svg";
import payment3 from "../../assets/svgs/Payment (2).svg";
import { fDate } from "../../utils/formatTime";

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: "order_type",
    numeric: false,
    disablePadding: true,
    label: "Description",
  },
  {
    id: "amount",
    numeric: true,
    disablePadding: false,
    label: "Amount",
  },
  {
    id: "order_id",
    numeric: true,
    disablePadding: false,
    label: "Payment ID",
  },
  {
    id: "status",
    numeric: false,
    disablePadding: false,
    label: "Status",
  },
  {
    id: "date",
    numeric: true,
    disablePadding: false,
    label: "Date",
  },
  {
    id: "info",
    numeric: false,
    disablePadding: false,
    label: "Info",
  },
];

// TransactionHistory.propTypes = {
//   heading: PropTypes.string.isRequired,
// };

function TransactionHistory(props) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              "aria-label": "select all transactions",
            }}
          />
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            sx={{ color: "#b6b8b9" }}
            key={headCell.id}
            align={headCell.numeric ? "left" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >

            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

TransactionHistory.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

export default function EnhancedTable({transactions}) {
 
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("amount");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = transactions.map((n) => n.order_id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  // eslint-disable-next-line camelcase
  const handleClick = (event, order_id) => {
    const selectedIndex = selected.indexOf(order_id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, order_id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // eslint-disable-next-line camelcase
  const isSelected = (order_id) => selected.indexOf(order_id) !== -1;

  const visibleRows = React.useMemo(
    () =>
      stableSort(transactions, getComparator(order, orderBy)).slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage
      ),
    [order, orderBy, page, rowsPerPage]
  );

  return (
    <Box sx={{ width: "100%" }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          mt: { xs: 2, md: 0 },
        }}
      >
        <Typography
          variant="body1"
          sx={{ fontWeight: 500, color: "#000", mb: 1 }}
        >
          Transaction History{" "}
        </Typography>
        <Typography
          variant="body2"
          sx={{ fontWeight: 500, color: "#17324f", mb: 1 }}
        >
          View all{" "}
        </Typography>
      </Box>

      <Paper sx={{ width: "100%", mb: 2 }}>
        <TableContainer>
          <Table
            sx={{
              minWidth: 750,
              background: "#fff",
              "&::-webkit-scrollbar": { width: 5, height: 5 },
              "&::-webkit-scrollbar-track": { backgroundColor: "#ccc" },
              "&::-webkit-scrollbar-thumb": {
                backgroundColor: "black",
                borderRadius: 20,
              },
            }}
            aria-labelledby="tableTitle"
          >
            <TransactionHistory
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={transactions.length}
            />
            <TableBody>
              {visibleRows.map((row, index) => {
                const isItemSelected = isSelected(row.order_id);
                const labelId = `enhanced-table-checkbox-${index}`;

                return (
                  <TableRow
                    hover
                    onClick={(event) => handleClick(event, row.order_id)}
                    role="checkbox"
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={row.order_id}
                    selected={isItemSelected}
                    sx={{ cursor: "pointer" }}
                  >
                    <TableCell padding="checkbox">
                      <Checkbox
                        color="primary"
                        checked={isItemSelected}
                        inputProps={{
                          "aria-labelledby": labelId,
                        }}
                      />
                    </TableCell>

                    <TableCell align="left">
                      <Box
                        sx={{
                          display: "flex",
                          gap: 1,
                          justifyContent: "flex-start",
                          ml: -1,
                        }}
                      >
                        {row.status === "Payment Pending" ? (
                          <img src={payment1} alt="icons" />
                        ) : (
                          <img src={payment3} alt="icons" />
                        )}
                        <Typography sx={{ color: "#151a1d" }}>
                          {row.order_type}{" "}
                          <span style={{ fontSize: "10px", color: "#939697" }}>
                            {row.sub}
                          </span>
                        </Typography>
                      </Box>
                    </TableCell>
                    <TableCell align="left" sx={{ color: "#626668" }}>
                      {row.amount}
                    </TableCell>
                    <TableCell align="left">{row.order_id}</TableCell>
                    <TableCell align="left">
                      <Button
                        sx={{
                          background:
                            row.status === "Payment Pending"
                              ? "#fbf2e6"
                              : "#e9f7f0",
                          color:
                            row.status === "Payment Pending"
                              ? "#db8000"
                              : "#168250",
                        }}
                      >
                        {row.status}
                      </Button>
                    </TableCell>
                    <TableCell align="left">{fDate(row.date)}</TableCell>
                    <TableCell align="left" sx={{ color: "#17324f" }}>
                      {row.info}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={transactions.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </Box>
  );
}
