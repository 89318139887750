import { Helmet } from "react-helmet-async";
// @mui

import { Container, Typography } from "@mui/material";

// sections

// ----------------------------------------------------------------------

export default function SettingsPage() {
  return (
    <>
      <Helmet>
        <title> Settings | Educarts </title>
      </Helmet>

      <Container maxWidth="xl">
        <Typography variant="h4" sx={{ mb: 5 }}>
          Settings
        </Typography>
        <Typography variant="subtitle">Coming soon</Typography>
      </Container>
    </>
  );
}
