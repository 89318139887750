import { Helmet } from "react-helmet-async";
// @mui

import { Container} from "@mui/material";

// sections
import DashboardAppPage from "./DashboardAppPage";
// ----------------------------------------------------------------------

export default function HomePage() {
  return (
    <>
      <Helmet>
        <title> Home | Educarts </title>
      </Helmet>

      <Container maxWidth="xl">
      <DashboardAppPage />
      </Container>
    </>
  );
}
