import { Helmet } from "react-helmet-async";
// @mui
import { Container, Stack } from "@mui/material";

// components
// import Iconify from "../components/iconify";

import {
  ProfileCard,
  ContactCard,
  PaymentCard,
} from "../../sections/@dashboard/account";

import Header from "./Header";
// ----------------------------------------------------------------------

// ----------------------------------------------------------------------

export default function ProfilePage() {
  return (
    <>
      <Helmet>
        <title> Dashboard: Account: Profile</title>
      </Helmet>
      <Header />
      <Container>
        <Stack sx={{ mt: 10 }} spacing={{ xs: 8, lg: 6 }}>
          <ProfileCard />
          <ContactCard />
          <PaymentCard />
        </Stack>
      </Container>
    </>
  );
}
