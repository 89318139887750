import React from "react";
import { styled } from "@mui/material/styles";
import { Box, Avatar, Container, Stack, Typography } from "@mui/material";

const StyledHeader = styled("div")(({ theme }) => ({
  background: "linear-gradient(270deg, #96BEC7 0%, #B0BE9E 60%, #F1D2BA 100%)",
  paddingTop: theme.spacing(3),
  paddingBottom: theme.spacing(3),
  textAlign: "center",
  "&:after": {
    content: '""',
    position: "absolute",
    width: "60px",
    height: "60px",
    backgroundImage: `url("/assets/icons/spring.svg")`,
    opacity: "20%",
    top: "90px",
    right: "5px",
  },
}));

const ProfilePicture = () => (
  <Box
    sx={{
      width: 100,
      height: 100,
      borderRadius: "50%",
      background: "black",
      transform: "translateY(20%)",
    }}
  >
    <Avatar sx={{cursor:"pointer", position: "absolute", bottom: "-5px", right: "-10px" }} />
  </Box>
);

function Header() {
  return (
    <>
      <StyledHeader>
        <Container>
          <Stack
            sx={{
              flexDirection: "row",
              alignItems: "center",
              display: "flex",
              justifyContent: "flex-start",
              gap: 2,
              height: "30px",
            }}
          >
            <ProfilePicture />

            <Box
              sx={{textAlign: "left"}}>
              <Typography variant="h6"> John Adewale Njoku</Typography>
              <Typography
                variant="caption"
                sx={{ textDecoration: "underline" }}
              >
                Change profile picture
              </Typography>
            </Box>
          </Stack>
        </Container>
      </StyledHeader>
    </>
  );
}

export default Header;
