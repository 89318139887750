/* eslint-disable react/jsx-key */
import { useRef, useState } from "react";
import axios from "axios";
import classNames from "classnames";
import { RadioGroup } from "@headlessui/react";
import { v4 as uuidv4 } from "uuid";
import { Helmet } from "react-helmet-async";

// import FormRadioCard from '../../components/form/FormRadioCard';
import PriceHeader from "../../components/form/PriceHeader";
import PersonalInfo from "../../components/form/sevis-form-1/PersonalInfo";
import ValidationInfo from "../../components/form/sevis-form-1/ValidationInfo";
// import DocUpload from '../../components/form/sevis-form-1/DocUpload';
// import Confirm from '../../components/form/sevis-form-1/Confirm';

import PersonalInfo2 from "../../components/form/sevis-form-2/PersonalInfo";
import ValidationInfo2 from "../../components/form/sevis-form-2/ValidationInfo";
// import DocUpload2 from '../../components/form/sevis-form-2/DocUpload';

import { ReactComponent as Val } from "../../assets/svgs/appli.svg";
import { ReactComponent as FormIndicator } from '../../assets/svgs/copy.svg';
import { ReactComponent as CheckMark } from '../../assets/svgs/sevis.svg';
// import { ReactComponent as Circ } from '../../assets/svgs/circ.svg';
import Sevis from "../../assets/svgs/cred.svg";
import PaymentHeader from "./PaymentHeader";

const formType = [
  {
    title: "DS 2019 J-1",
    desc: "for schools, and colleges",
    img: "/images/trainees/employed.svg",
  },
  {
    title: "Form 1-20",
    desc: "for schools, and colleges",
    img: "/images/trainees/unemployed.svg",
  },
];

const SevisForm = () => {
  const [step, setStep] = useState(0);
  const [selectedForm, setSelectedForm] = useState("form1");
  const fileInputRef = useRef(null);
  const [selectedFileName, setSelectedFileName] = useState("");
  const [selectedCategory, setSelectedCategory] = useState(null);

  const [state, setState] = useState({
    category: "",
    sevis_id: "",
    last_name: "",
    given_name: "",
    date_of_birth: "",

    email: "",
    phone_number: "",
    country_of_birth: "",
    country_of_citizenship: "",
    street_address_1: "",
    street_address_2: "",
    state: "",
    city: "",

    sevisID1: "",
    lastName1: "",
    givenName1: "",

    email1: "",
    phoneNumber1: "",
    countryOfBirth1: "",
    countryOfResidence1: "",
  });

  function handleInputChange(key) {
    return (e) => handleChange(key)(e.target.value);
  }

  function handleChange(key) {
    return (value) => {
      if (key === "resume") {
        const file = value.target.files[0];
        setSelectedFileName(file ? file.name : "");
      }
      setState((prevState) => ({ ...prevState, [key]: value }));
    };
  }

  const handleSelect = (category) => {
    setSelectedCategory(category);
  };

  // function handleFileInputChange(event) {
  //   if (event.target.files.length > 0) {
  //     setSelectedFileName(true);
  //   } else {
  //     setSelectedFileName(false);
  //   }
  //   handleChange('resume')(event);
  // }

  async function handleSubmit(e) {
    e.preventDefault();

    try {
      const formData = new FormData();

      Object.entries(state).forEach(([key, value]) => {
        if (key === "resume") {
          formData.append(key, fileInputRef.current.files[0]);
        } else {
          formData.append(key, value);
        }
      });

      await axios.post(
        "https://educartapi.herokuapp.com/api/v1/sevis/information/guest",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      nextHandler();
    } catch (error) {
      console.error(error);
    }
  }

  function nextHandler() {
    window.scrollTo({
      top: 0,
    });
    setStep(step + 1);
  }

  function prevHandler() {
    window.scrollTo({
      top: 0,
    });
    setStep(step - 1);
  }

  const contentProps = {
    state,
    setState,
    selectedFileName,
    fileInputRef,
    handleInputChange,
    // handleFileInputChange,
    handleSelect,
    selectedCategory,
    handleChange,
    nextHandler,
    prevHandler,
    handleSubmit,
  };

  const form1Components = [
    <ValidationInfo {...contentProps} />,
    <PersonalInfo {...contentProps} />,
    // <DocUpload {...contentProps} />,
    // <Confirm />,
  ];
  const form2Components = [
    <ValidationInfo2 {...contentProps} />,
    <PersonalInfo2 {...contentProps} />,
    // <DocUpload2 {...contentProps} />,
  ];

  const stepComponents =
    selectedForm === "form1" ? form1Components : form2Components;

  const toggleForm = (form) => {
    setSelectedForm(form);
    setStep(0);
  };

  return (
    <>
      <Helmet>
        <title>
          Payments | Sevis Fee | Form | Carry Out All Payments For Me
        </title>
        <meta name="description" content="Pay Tuition Fees Abroad" />
        <link rel="canonical" href="/payments/sevis" />
      </Helmet>
      <PaymentHeader />
      <PriceHeader />
      <div className="container pt-8 pb-14">
        <div className="max-w-[894px] mx-auto">
          {/* <ProgressBar currentStep={step} totalSteps={stepComponents.length} /> */}
          <div className="border-[1px] border-[#ECECEC] shadow-md bg-[#FFF] rounded-lg space-y-8 mt-14 p-4 md:px-20">
            <div className="flex flex-col items-center justify-center w-full">
              {step === 0 && (
                <>
                  {/* <div className='flex items-center justify-between'>
                    <div className='flex items-center gap-3'>
                      <FormIndicator />
                      <p>Validation Information</p>
                    </div>

                    <CheckMark />
                  </div> */}
                  <div className='border border-b-[1px] border-[#20212C] my-4' />

                  <div className="border-[2px] md:flex gap-2 px-8 rounded-md items-center border-[#34BBFF] bg-[#E1F6FF]">
                    <Val className="w-12" />
                    <p className="text-[14px] font-medium leading-[24px] text-[#656568]">
                      Choose a form type to get started, this helps us know the
                      information to collect better
                    </p>
                  </div>
                  <div className="grid gap-10 pt-8 md:grid-cols-2">
                    {/* <RadioGroup
                      // onChange={handleChange('employmentStatus')}
                      value={selectedForm}
                      onChange={(value) => toggleForm(value)}
                      className='mt-2'
                    >
                      <div className='grid grid-cols-2 gap-3 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5'>
                        {formType.map((type, i) => (
                          <RadioGroup.Option key={uuidv4()} value={type.title}>
                            {({ checked }) => (
                              <FormRadioCard
                                title={type.title}
                                img={type.img}
                                checked={checked}
                              />
                            )}
                          </RadioGroup.Option>
                        ))}
                      </div>
                    </RadioGroup> */}
                    <div
                      role="presentation"
                      className={classNames(
                        "flex items-center gap-4 rounded-md p-2 px-8 cursor-pointer",
                        "focus:outline-none",
                        {
                          "bg-[#E9EDF1] border-[#20466F] border-2":
                            selectedForm === "form1",
                          "bg-[#FEFEFF] border-[#DEE3E4] border-2":
                            selectedForm !== "form1",
                        }
                      )}
                      onClick={() => toggleForm("form1")}
                    >
                      <div>
                        <img src={Sevis} alt="Sevis-Img" />
                      </div>
                      <div>
                        <p className="text-[#1D4065] text-[18px] font-medium">
                          DS 2019 J-1
                        </p>
                        <p className="text-[#939697] text-[14px] font-medium">
                          for schools, and colleges
                        </p>
                      </div>
                    </div>
                    <div
                      role="presentation"
                      className={classNames(
                        "focus:outline-none md:mr-4 cursor-pointer px-8 flex items-center gap-4 rounded-md p-2",
                        {
                          "bg-[#E9EDF1] border-[#20466F] border-2":
                            selectedForm === "form2",
                          "bg-[#FEFEFF] border-[#DEE3E4] border-2":
                            selectedForm !== "form2",
                        }
                      )}
                      onClick={() => toggleForm("form2")}
                    >
                      <img src={Sevis} alt="Sevis-Img" />
                      <div>
                        <p className="text-[#1D4065] text-[18px] font-medium">
                          Form 1-20
                        </p>
                        <p className="text-[#939697] text-[14px] font-medium">
                          for schools, and colleges
                        </p>
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>

            <div className="mt-2">
              {stepComponents.map((component, index) => (
                <div
                  key={index}
                  className={classNames(index === step ? "block" : "hidden")}
                >
                  {component}
                </div>
              ))}
              {/* {stepComponents[step]} */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SevisForm;
