import PropTypes from "prop-types";
import React, { useState } from "react";
import { Link } from "react-router-dom";
// @mui
import {
  Box,
  Card,
  //   Link,
  Divider,
  Typography,
  Stack,
  Button,
  Input,
  FormControl,
  IconButton,
  InputAdornment,
  TextField,
} from "@mui/material";

// import { styled } from "@mui/material/styles";
import CardHeader from "./CardHeader";
import Iconify from "../../../components/iconify";
import PasswordTop from "./PasswordTop";
// components

// ----------------------------------------------------------------------

// ----------------------------------------------------------------------

PasswordCard.propTypes = {
  //   product: PropTypes.object,
};

export default function PasswordCard() {
  const [showPassword, setShowPassword] = useState(false);

  return (
    <Box>
        <PasswordTop />
    <Card sx={{ px: { xs: 1, sm: 4 } }}>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          pb: 2,
          px: 3,
          pt: 3,
        }}
      >
        <Box>
          <Typography sx={{ fontWeight: 600 }}>Password</Typography>
        </Box>
      </Box>
      <Divider />
      <Stack spacing={2} sx={{ p: 3 }}>
        <CardHeader
          text={"Read the guide above before creating a New Password"}
        />
      </Stack>

      <Box
        sx={{
          pt: { xs: 3, md: 1 },
          pb: 8,
          px: { xs: 2, lg: 3 },
        }}
      >
        <Stack
          sx={{
            display: "flex",
            flexDirection: { xs: "column", md: "row" },
            justifyContent: "",
            alignItems: { xs: "left", md: "center" },
            gap: 3,
          }}
        >
          <FormControl sx={{ width: { md: "50%" } }}>
            <Typography variant="body2" sx={{ mb: 1, color: "gray" }}>
              Enter Current Password*
            </Typography>
            <TextField
              fullWidth
              name="password"
              label="**********"
              type={showPassword ? "text" : "password"}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() => setShowPassword(!showPassword)}
                      edge="end"
                    >
                      <Iconify
                        icon={
                          showPassword ? "eva:eye-fill" : "eva:eye-off-fill"
                        }
                      />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </FormControl>

          <FormControl sx={{ width: { md: "50%" } }}>
            <Typography variant="body2" sx={{ mb: 1, color: "gray" }}>
              Enter New Password*
            </Typography>
            <TextField
              fullWidth
              name="password"
              label="Enter Password"
              type={showPassword ? "text" : "password"}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() => setShowPassword(!showPassword)}
                      edge="end"
                    >
                      <Iconify
                        icon={
                          showPassword ? "eva:eye-fill" : "eva:eye-off-fill"
                        }
                      />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </FormControl>
        </Stack>

        <Stack
          sx={{
            display: "flex",
            flexDirection: { xs: "column", md: "row" },
            justifyContent: "space-between",
            mt: { xs: 3, md: 6 },
            gap: 2,
          }}
        >
          <Box>
            <Typography
              variant="body2"
              sx={{ mb: 2, color: "#939697", fontWeight: 700 }}
            >
              Can't remember password?{" "}
              <Link
                to="/forgot-password"
                variant="subtitle2"
                style={{
                  textDecoration: "none",
                  color: "#17324f",
                  fontWeight: "700",
                }}
                underline="hover"
              >
                {" "}
                Reset My Password
              </Link>
            </Typography>
          </Box>

          <Box sx={{ mt: { md: 10 }, display: "flex", gap: 3 }}>
            <Button
              sx={{
                border: "1px solid #20466f",
                color: "#20466f",
                width: 150,
                height: 40,
                boxShadow: 3,
                fontWeight: 500,
              }}
            >
              Cancel
            </Button>

            <Button
              sx={{
                background: "#20466f",
                color: "#fff",
                width: 150,
                height: 40,
                boxShadow: 3,
                fontWeight: 500,
              }}
            >
              Save
            </Button>
          </Box>
        </Stack>
      </Box>
    </Card>
    </Box>
  );
}
