// component
import SvgColor from "../../../components/svg-color";

// ----------------------------------------------------------------------

const icon = (name) => (
  <SvgColor
    src={`/assets/icons/navbar/${name}.svg`}
    sx={{ width: 1, height: 1 }}
  />
);

const navConfig = [
  {
    title: "home",
    path: "/home",
    icon: icon("ic_home"),
  },
  {
    title: "inbox",
    path: "/inbox",
    icon: icon("ic_inbox"),
  },
  {
    title: "payments",
    path: "/payments",
    icon: icon("ic_payment"),
  },
  {
    title: "support",
    path: "/support",
    icon: icon("ic_support"),
  },
  {
    title: "account",
    path: "/account",
    icon: icon("ic_account"),
    drop:"hey"
  },
  {
    title: "security",
    path: "/security",
    icon: icon("ic_security"),
  },
  {
    title: "Settings",
    path: "/settings",
    icon: icon("ic_settings"),
  },
];

export default navConfig;
