// @mui
import { Input, Box, InputAdornment } from "@mui/material";
// component
import Iconify from "../../../components/iconify";

// ----------------------------------------------------------------------

// ----------------------------------------------------------------------

export default function Searchbar() {
  return (
    <Box>
      <div>
        <Input
          autoFocus
          disableUnderline
          placeholder="Type to search…"
          style={{
            width: 300,
            px: 3,
            height: 40,
            border: "1px solid gray",
            fontSize: "15px",
          }}
          inputProps={{
            sx: {
              "&::placeholder": {
                fontSize: "15px",
              },
            },
          }}
          startAdornment={
            <InputAdornment position="start">
              <Iconify
                icon="eva:search-fill"
                sx={{
                  color: "#151a1d",
                  cursor: "pointer",
                  width: 20,
                  height: 20,
                  ml: 1,
                }}
              />
            </InputAdornment>
          }
          sx={{ mr: 1, fontWeight: 500 }}
        />
      </div>
    </Box>
  );
}
