import { Navigate, useRoutes } from "react-router-dom";
// layouts
import DashboardLayout from "./layouts/dashboard";
import SimpleLayout from "./layouts/simple";
//
import LoginPage from "./pages/Auth/LoginPage";
import ForgotPassword from "./pages/Auth/ForgotPassword";
import GetOtp from "./pages/Auth/GetOtp";
import ResetPassword from "./pages/Auth/ResetPassword";
import Register3 from "./pages/Auth/RegisterPage3";
import Steps from "./pages/Auth/Steps";

import HomePage from "./pages/HomePage";
import InboxPage from "./pages/InboxPage";
import PaymentPage from "./pages/PaymentPage";
import SupportPage from "./pages/SupportPage";
import ConsultationPage from "./pages/ConsultationPage";
import TransactionsPage from "./pages/TransactionsPage";
// import SecurityPage from "./pages/SecurityPage";
import SettingsPage from "./pages/SettingsPage";
import ProfilePage from "./pages/Account/ProfilePage";
import IdentificationPage from "./pages/Account/IdentificationPage";
import SecurityPage from "./pages/Account/SecurityPage";
import Page404 from "./pages/Page404";
import ProtectedRoute from "./components/ProtectedRoute";
import AuthRoute from "./components/AuthRoute";
// ----------------------------------------------------------------------
import Sevis from "./pages/Payment/Sevis";
import SevisForm from "./pages/Payment/SevisForm";
import PaymentFlow from "./pages/PaymentFlow";

// ----------------------------------------------------------------------

export default function Router() {
  const routes = useRoutes([
    {
      path: "/",
      element: (
        <ProtectedRoute>
          <DashboardLayout PageName={"Dashboard"} />
        </ProtectedRoute>
      ),
      children: [
        { element: <Navigate to="/home" />, index: true },
        { path: "home", element: <HomePage PageName={"Dashboard"} /> },
        { path: "inbox", element: <InboxPage PageName={"Inbox"} /> },
        { path: "payments", element: <PaymentPage PageName={"Payment"} /> },
        { path: "support", element: <SupportPage PageName={"Support"} /> },
        { path: "consultation", element: <ConsultationPage PageName={"Consultation"} /> },
        { path: "transactions", element: <TransactionsPage PageName={"Transaction"} /> },
        {
          path: "account/security",
          element: <SecurityPage PageName={"Security"} />,
        },
        { path: "settings", element: <SettingsPage PageName={"Settings"} /> },
        {
          path: "account/profile",
          element: <ProfilePage PageName={"Account"} />,
        },
        {
          path: "account/identification",
          element: <IdentificationPage PageName={"Account"} />,
        },
        {
          path: "payments/sevis",
          element: <Sevis PageName={"Payment"} />,
        },
        {
          path: "payments/sevis/form",
          element: <SevisForm PageName={"Payment"} />,
        },
        {
          path: "payments/sevis/pay",
          element: <PaymentFlow PageName={"Payment"} />,
        },
      ],
    },
    {
      path: "login",
      element: (
        <AuthRoute>
          <LoginPage />
        </AuthRoute>
      ),
    },
    {
      path: "forgot-password",
      element: (
        <AuthRoute>
          <ForgotPassword />
        </AuthRoute>
      ),
    },
    {
      path: "get-otp",
      element: (
        <AuthRoute>  
          <GetOtp />
        </AuthRoute>
      ),
    },
    {
      path: "reset-password",
      element: (
        <AuthRoute>
          <ResetPassword />
        </AuthRoute>
      ),
    },
    {
      path: "register",
      element: (
        <AuthRoute>
          <Steps />
        </AuthRoute>
      ),
    },
    {
      path: "signup-confirm-code",
      element: (
        <AuthRoute>
          <Register3 />
        </AuthRoute>
      ),
    },
    {
      element: <SimpleLayout />,
      children: [
        { element: <Navigate to="/dashboard/app" />, index: true },
        { path: "404", element: <Page404 /> },
        { path: "*", element: <Navigate to="/404" /> },
      ],
    },
    {
      path: "*",
      element: <Navigate to="/404" replace />,
    },
  ]);

  return routes;
}
