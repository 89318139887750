import PropTypes from "prop-types";
// @mui
import {
  Box,
  Card,
  //   Link,
  Divider,
  Typography,
  Stack,
  Button,
  Input,
  FormControl,
} from "@mui/material";
// import { styled } from "@mui/material/styles";
import CardHeader from "./CardHeader";
// utils
// import { fCurrency } from "../../../utils/formatNumber";
// components

// ----------------------------------------------------------------------

// ----------------------------------------------------------------------

ProfileCard.propTypes = {
  //   product: PropTypes.object,
};

export default function ProfileCard() {
  return (
    <Card sx={{ px: { xs: 1, sm: 4 } }}>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          pb: 2,
          px: 3,
          pt: 3,
        }}
      >
        <Box>
          <Typography sx={{ fontWeight: 600 }}>Personal Information</Typography>
        </Box>
        <Button
          sx={{
            border: "1px solid #20466f",
            color: "#20466f",
            borderRadius: "30px",
            width: 100,
            height: 35,
          }}
        >
          Edit
        </Button>
      </Box>
      <Divider />
      <Stack spacing={2} sx={{ p: 3 }}>
        <CardHeader text={"We suggest you enter the name as it appears on your Government ID"}/>
      </Stack>

      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", md: "row" },
          alignItems: { xs: "left", md: "center" },
          gap: { xs: 4, md: 20, lg: 40 },
          pt: 3,
          pb: 8,
          px: { xs: 2, lg: 3 },
        }}
      >
        <Stack spacing={4}>
          <FormControl>
            <Typography sx={{ mb: 1, color: "gray", fontSize: "14px" }}>
              First Name
            </Typography>
            <Input
              size="small"
              disableUnderline
              autoFocus
              name="first_name"
              placeholder="John"
              inputProps={{
                sx: {
                  "&::placeholder": {
                    fontSize: "15px",
                    color: "#000",
                    fontWeight: 600,
                  },
                },
              }}
            />
          </FormControl>

          <FormControl>
            <Typography sx={{ mb: 1, color: "gray", fontSize: "14px" }}>
              Email
            </Typography>
            <Input
              size="small"
              disableUnderline
              autoFocus
              name="email"
              placeholder="JohnAdeogun@gmail.com"
              inputProps={{
                sx: {
                  "&::placeholder": {
                    fontSize: "15px",
                    color: "#000",
                    fontWeight: 600,
                  },
                },
              }}
            />
          </FormControl>
        </Stack>

        <Stack spacing={4}>
          <FormControl>
            <Typography sx={{ mb: 1, color: "gray", fontSize: "14px" }}>
              Last Name
            </Typography>
            <Input
              size="small"
              disableUnderline
              autoFocus
              name="last_name"
              placeholder="Adewale"
              inputProps={{
                sx: {
                  "&::placeholder": {
                    fontSize: "15px",
                    color: "#000",
                    fontWeight: 600,
                  },
                },
              }}
            />
          </FormControl>

          <FormControl>
            <Typography sx={{ mb: 1, color: "gray", fontSize: "14px" }}>
              Phone Number{" "}
            </Typography>
            <Input
              size="small"
              disableUnderline
              autoFocus
              name="phone_number"
              placeholder="+234-90198-4196"
              inputProps={{
                sx: {
                  "&::placeholder": {
                    fontSize: "15px",
                    color: "#000",
                    fontWeight: 600,
                  },
                },
              }}
            />
          </FormControl>
        </Stack>
      </Box>
    </Card>
  );
}
