import PropTypes from "prop-types";
// @mui
import { styled } from "@mui/material/styles";
import {
  Box,
  Stack,
  AppBar,
  Toolbar,
  Typography,
  IconButton,
  Container,
  // Divider,
} from "@mui/material";
// utils
import { bgBlur } from "../../../utils/cssStyles";
// components
import Iconify from "../../../components/iconify";
//
import Searchbar from "./Searchbar";
import SearchbarMobile from "./SearchbarMobile";
import NotificationsPopover from "./NotificationsPopover";
import hand from "../../../assets/svgs/Waving Hand.svg";

// ----------------------------------------------------------------------

const NAV_WIDTH = 240;

const HEADER_MOBILE = 64;

const HEADER_DESKTOP = 92;

const StyledRoot = styled(AppBar)(({ theme }) => ({
  ...bgBlur({ color: theme.palette.background.default }),
  boxShadow: "none",
  [theme.breakpoints.up("lg")]: {
    width: `calc(100% - ${NAV_WIDTH + 1}px)`,
  },
}));

const StyledToolbar = styled(Toolbar)(({ theme }) => ({
  minHeight: HEADER_MOBILE,
  [theme.breakpoints.up("lg")]: {
    minHeight: HEADER_DESKTOP,
    padding: theme.spacing(0, 5),
  },
}));

// ----------------------------------------------------------------------

Header.propTypes = {
  onOpenNav: PropTypes.func,
  HeaderText: PropTypes.string,
};

export default function Header({ onOpenNav, HeaderText }) {
  const refreshToken = localStorage.getItem("userData");
  // const { firstName } = JSON.parse(refreshToken);
  const { lastName } = JSON.parse(refreshToken);

  return (
    <StyledRoot>
      <Container maxWidth="xl">
        <StyledToolbar>
          <Box sx={{ display: "flex", alignItems: "center", right: 30, position:"relative" }}>
            <IconButton
              onClick={onOpenNav}
              sx={{
                color: "text.primary",
                display: { lg: "none" },
                position: "relative",
                gap: 12,
              }}
            >
              <Iconify icon="eva:menu-2-fill" color="#151a1d" />
            </IconButton>
            <Box sx={{ display: { lg: "none" } }}>
              <Typography
                variant="h6"
                sx={{ fontWeight: 500, color: "#151a1d" }}
              >
                {HeaderText}
              </Typography>
            </Box>
          </Box>
          <Box sx={{ display: { xs: "none", lg: "block" } }}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: 1,
              }}
            >
              <Typography
                variant="h6"
                sx={{
                  color: "#000",
                  fontWeight: 500,
                  textTransform:"capitalize"
                }}
              >
                Hello, {lastName}
              </Typography>

              <img src={hand} alt="waving hand" />
            </Box>
          </Box>

          <Box sx={{ flexGrow: 1 }} />

          <Stack
            direction="row"
            alignItems="center"
            spacing={{
              sm: 4,
            }}
          >
            <Box sx={{ display: { xs: "none", md: "block" } }}>
              <Searchbar />
            </Box>
            <Box sx={{ display: { md: "none" } }}>
              <SearchbarMobile />
            </Box>
            <NotificationsPopover />
          </Stack>
        </StyledToolbar>
      </Container>
    </StyledRoot>
  );
}
