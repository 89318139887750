/* eslint-disable react/prop-types */
import classNames from 'classnames';

const Input = ({
  name,
  label,
  type,
  value,
  placeHolder,
  required,
  className,
  ...rest
}) => {
  const baseClassNames = classNames(
    'block w-full mt-2 bg-white placeholder:text-[#A6A6A8] text-sm px-4 py-3.5 rounded-md shadow-sm border border-[#ECECEC] focus:border-[#20466F] focus:outline-none',
    className
  );

  return (
    <div className='space-y-2'>
      <label className='text-sm font-bold text-black' htmlFor={name}>
        {label}
        {required && <span className='text-[#EB5757] ml-1'>*</span>}
      </label>
      <input
        className={baseClassNames}
        id={name}
        name={name}
        type={type}
        value={value}
        placeholder={placeHolder}
        {...rest}
      />
    </div>
  );
};

export default Input;