import { Helmet } from "react-helmet-async";
// @mui
import { Container, Stack } from "@mui/material";

// components
// import Iconify from "../components/iconify";

import { IdentificationCard } from "../../sections/@dashboard/account";
// mock

// ----------------------------------------------------------------------
import Header from "./Header";
// ----------------------------------------------------------------------

export default function identificationPage() {
  return (
    <>
      <Helmet>
        <title> Dashboard: Account: Identification</title>
      </Helmet>
      <Header />
      <Container>
        <Stack sx={{ mt: 10 }} spacing={{ xs: 8, lg: 6 }}>
          <IdentificationCard />
        </Stack>
      </Container>
    </>
  );
}
