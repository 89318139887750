import { Helmet } from "react-helmet-async";
// @mui
import { styled } from "@mui/material/styles";
import { Typography, Stack, Grid } from "@mui/material";

// components
import Logo from "../../components/logo";

// sections
import { RegisterForm } from "../../sections/auth/register";
// ----------------------------------------------------------------------

const FormArea = styled("div")(({ theme }) => ({
  border: "1px solid #E8E8E8",
  borderRadius: "20px",
  padding: "25px ",
  boxShadow: theme.customShadows.card,
  backgroundColor: theme.palette.background.default,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
}));

// ----------------------------------------------------------------------

// eslint-disable-next-line react/prop-types
export default function RegisterPage({ handleStep }) {
  return (
    <>
      <Helmet>
        <title> Register | Educarts </title>
      </Helmet>
      <Grid container component="main" sx={{ height: "100vh" }}>
        <Grid
          item
          xs={12}
          md={7}
          elevation={6}
          // square
          sx={{
            mt: 8,
            mb: 4,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <FormArea
            sx={{
              maxWidth: { xs: "450px", lg: "500px", xl: "600px" },
              width: { lg: "600px" },
            }}
          >
            <Stack alignItems="center">
              <Logo sx={{ width: 150, mb: 3 }} />

              <Typography variant="h4">Choose your account type</Typography>

              <Typography
                variant="body2"
                sx={{ mb: 3, color: "#939697", fontWeight: 500 }}
              >
                Pick an account type to get started
              </Typography>
            </Stack>

            <RegisterForm handleStep={handleStep} />
          </FormArea>
        </Grid>

        <Grid
          item
          xs={false}
          md={5}
          sx={{
            backgroundImage: "url(/assets/educarts/auth_image.png)",
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        />
      </Grid>
    </>
  );
}
