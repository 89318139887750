import { Stack, Box, Typography } from "@mui/material";
import card1 from "../../../assets/svgs/card.svg";
import card2 from "../../../assets/svgs/card (1).svg";

export default function CardRadio({ card }) {
  return (
    <>
      <Stack
        direction="row"
        spacing={2}
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          mr: 3,
        }}
      >
        {card ? <img src={card2} alt="card" /> : <img src={card1} alt="card" />}
        <Box>
          <Typography
            sx={{ color: card ? "#20466f" : "#939697" }}
            variant="subtitle2"
          >
            Credit/Debit Card
          </Typography>
          <Typography
            sx={{ color: card ? "#20466f" : "#939697" }}
            variant="caption"
          >
            for you, family or friends{" "}
          </Typography>
        </Box>
      </Stack>
    </>
  );
}
