import { Stack, Box, Typography } from "@mui/material";

export default function InstituteRadio() {
  return (
    <>
      <Stack
        direction="row"
        spacing={2}
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          mr: 3,
        }}
      >
        <img src="/assets/educarts/institute.png" alt="institute" />
        <Box>
          <Typography variant="h6">Institution Account </Typography>
          <Typography variant="subtitle2">for schools, and colleges</Typography>
        </Box>
      </Stack>
    </>
  );
}
