import { useState, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
// @mui
import {
  Stack,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
  Box,
  Checkbox,
  FormHelperText,
  InputLabel,
  Grid,
} from "@mui/material";

import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/material.css";
import { LoadingButton } from "@mui/lab";
// components
import Iconify from "../../../components/iconify";
import Toast from "../../../components/ToastAlert";
import ButtonLoader from "../../../components/ButtonLoader";
import axiosClient from "../../../axios-client";
// ----------------------------------------------------------------------

// eslint-disable-next-line react/prop-types
function RegisterForm2() {
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [disabledButton, setDisabledButton] = useState(false);

  const userType = "user";

  const formik = useFormik({
    initialValues: {
      first_name: "",
      last_name: "",
      email: "",
      country_of_residence: "",
      phone_number: "",
      country_code: "234" || "",
      user_type: userType,
      password: "",
      acceptedTerms: false,
    },
    validationSchema: Yup.object({
      first_name: Yup.string().max(15).min(3).required("Required!"),

      last_name: Yup.string().max(15).min(3).required("Required!"),

      country_of_residence: Yup.string().max(15).min(4).required("Required!"),

      email: Yup.string().email("Invalid email address").required("Required!"),

      phone_number: Yup.string()
        .max(10)
        .required("Ensure this field has no more than 10 characters.")
        .required("Required!"),

      password: Yup.string()
        .required("Password is required!")
        .min(8, "Password is too short - must be 8 characters or longer")
        .required("Required"),
      acceptedTerms: Yup.boolean().oneOf(
        [true],
        "You must accept the terms and conditions."
      ),
    }),

    validateOnChange: true,

    onSubmit: (values) => {
      setDisabledButton(true);
      setLoading(true);

      axiosClient
        .post("/v1/auth/register/", values)
        .then((response) => {
          if (response) {
            Toast.fire({
              icon: "success",
              title: `${response.data.message.success}`,
            });
            navigate("/signup-confirm-code");
            setLoading(false);
            setDisabledButton(false);
          }

          localStorage.setItem("userId", response.data.data.id);
          localStorage.setItem("userEmail", response.data.data.email);
        })
        .catch((err) => {
          setLoading(false);
          setDisabledButton(false);

          if (err.response) {
            const error = err.response.data.errorMessage;
            let errMessage = "";
            // eslint-disable-next-line no-restricted-syntax
            for (const key in error) {
              if (Object.hasOwnProperty.call(error, key)) {
                const element = error[key];
                // eslint-disable-next-line no-const-assign
                errMessage = element;
              }
            }
            Toast.fire({
              icon: "error",
              title: `${errMessage || err.response.statusText}`,
            });
            setLoading(false);
          } else {
            Toast.fire({
              icon: "error",
              title: `${err.message || err.response.statusText}`,
            });
          }
        });
    },
  });

  useEffect(() => {
    if (!(formik.isValid && formik.dirty)) {
      setDisabledButton(true);
    } else {
      setDisabledButton(false);
    }
  }, [formik.isValid, formik.dirty]);

  return (
    <form>
      <Stack spacing={2}>
        <Box>
          <InputLabel sx={{ mb: 1, color: "#17324f", fontWeight: 500 }}>
            First Name <span style={{ color: "red" }}>*</span>
          </InputLabel>
          <TextField
            name="first_name"
            label={formik.values.first_name === "" ? "Enter First Name" : ""}
            InputLabelProps={{
              shrink: false,
            }}
            value={formik.values.first_name}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            fullWidth
          />
          {formik.errors.first_name && formik.touched.first_name ? (
            <FormHelperText error> {formik.errors.first_name}</FormHelperText>
          ) : null}
        </Box>

        <Box>
          <InputLabel sx={{ mb: 1, color: "#17324f", fontWeight: 500 }}>
            Last Name <span style={{ color: "red" }}>*</span>
          </InputLabel>
          <TextField
            name="last_name"
            label={formik.values.last_name === "" ? "Enter Last Name" : ""}
            InputLabelProps={{
              shrink: false,
            }}
            value={formik.values.last_name}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            fullWidth
          />
          {formik.errors.last_name && formik.touched.last_name ? (
            <FormHelperText error> {formik.errors.last_name}</FormHelperText>
          ) : null}
        </Box>

        <Box>
          <InputLabel sx={{ mb: 1, color: "#17324f", fontWeight: 500 }}>
            Email Address <span style={{ color: "red" }}>*</span>
          </InputLabel>
          <TextField
            name="email"
            label={formik.values.email === "" ? "Enter Email" : ""}
            InputLabelProps={{
              shrink: false,
            }}
            value={formik.values.email}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            fullWidth
          />
          {formik.errors.email && formik.touched.email ? (
            <FormHelperText error> {formik.errors.email}</FormHelperText>
          ) : null}
        </Box>

        <Box>
          <InputLabel sx={{ mb: 1, color: "#17324f", fontWeight: 500 }}>
            Phone Number<span style={{ color: "red" }}>*</span>
          </InputLabel>
          <Grid container>
            <Grid item xs={4} sm={3.2}>
              <PhoneInput
                name="country_code"
                value={formik.values.country_code}
                onChange={formik.handleChange("country_code")}
                specialLabel=""
                inputProps={{
                  name: "country_code",
                  required: true,
                  style: {
                    width: "100%",
                    background: "transparent",
                    borderRightColor: "transparent",
                  },
                }}
                country={"ng"}
                countryCodeEditable={false}
              />
            </Grid>

            <Grid item xs={8} sm={8.8} sx={{}}>
              <TextField
                name="phone_number"
                type="number"
                value={formik.values.phone_number}
                onChange={formik.handleChange("phone_number")}
                onBlur={formik.handleBlur}
                inputProps={{
                  name: "phone_number",
                  required: true,
                  sx: {
                    width: "100%",
                    background: "transparent",
                  },
                }}
                fullWidth
              />
            </Grid>
            {formik.errors.phone_number && formik.touched.phone_number ? (
              <FormHelperText error>
                {" "}
                {formik.errors.phone_number}
              </FormHelperText>
            ) : null}
          </Grid>
        </Box>

        <Box>
          <InputLabel sx={{ mb: 1, color: "#17324f", fontWeight: 500 }}>
            Country of Residence<span style={{ color: "red" }}>*</span>
          </InputLabel>
          <TextField
            name="country_of_residence"
            label={
              formik.values.country_of_residence === "" ? "Enter Country" : ""
            }
            InputLabelProps={{
              shrink: false,
            }}
            value={formik.values.country_of_residence}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            fullWidth
          />
          {formik.errors.country_of_residence &&
          formik.touched.country_of_residence ? (
            <FormHelperText error>
              {" "}
              {formik.errors.country_of_residence}
            </FormHelperText>
          ) : null}
        </Box>

        <Box>
          <InputLabel sx={{ mb: 1, color: "#17324f", fontWeight: 500 }}>
            Password <span style={{ color: "red" }}>*</span>
          </InputLabel>
          <TextField
            fullWidth
            name="password"
            label={formik.values.password === "" ? "Enter Password" : ""}
            InputLabelProps={{
              shrink: false,
            }}
            value={formik.values.password}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            type={showPassword ? "text" : "password"}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={() => setShowPassword(!showPassword)}
                    edge="end"
                  >
                    <Iconify
                      icon={showPassword ? "eva:eye-fill" : "eva:eye-off-fill"}
                    />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          {formik.errors.password && formik.touched.password ? (
            <FormHelperText error> {formik.errors.password}</FormHelperText>
          ) : null}
        </Box>

        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "start",
          }}
        >
          <Checkbox
            name="acceptedTerms"
            value={formik.values.acceptedTerms}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />

          <Typography sx={{ color: "#20466f", ml: 2, fontSize: "15px" }}>
            {" "}
            By clicking Next you agree with our{" "}
            <Link
              to="/terms-and-conditions"
              style={{ textDecoration: "underline", cursor: "pointer" }}
            >
              Terms and Conditions
            </Link>{" "}
            and our{" "}
            <Link
              to="/privacy-policy"
              style={{ textDecoration: "underline", cursor: "pointer" }}
            >
              Privacy Policy
            </Link>
          </Typography>
        </Box>
        {formik.errors.acceptedTerms && formik.touched.acceptedTerms ? (
          <FormHelperText error> {formik.errors.acceptedTerms}</FormHelperText>
        ) : null}
      </Stack>

      <Stack alignItems="center">
        <LoadingButton
          sx={{ textAlign: "center", my: 2, px: 7, mt: 3 }}
          size="large"
          type="submit"
          variant="contained"
          disabled={disabledButton}
          onClick={formik.handleSubmit}
        >
          {!loading ? (
            "Next"
          ) : (
            <Box sx={{ alignItems: "center" }}>
              <ButtonLoader />
            </Box>
          )}
        </LoadingButton>
      </Stack>

      <Stack
        direction="column"
        alignItems="left"
        justifyContent="space-between"
        sx={{ my: 2, textAlign: "center" }}
      >
        <Typography
          sx={{ "&:hover": { textDecoration: "underline" } }}
          variant="body2"
        >
          <Link
            to="/forgot-password"
            style={{
              textDecoration: "none",
              color: "#17324f",
              fontWeight: "700",
            }}
          >
            Forgot password?
          </Link>
        </Typography>
        <Typography
          variant="body2"
          sx={{ mt: 1, mb: 3, color: "#939697", fontWeight: 700 }}
        >
          Already have an account?{" "}
          <Link
            to="/login"
            variant="subtitle2"
            style={{
              textDecoration: "none",
              color: "#17324f",
              fontWeight: "700",
              ml: 2,
            }}
            underline="hover"
          >
            {" "}
            Log In
          </Link>
        </Typography>
      </Stack>
    </form>
  );
}
export default RegisterForm2;
