import Swal from "sweetalert2";
import './toast.css'

const Toast = Swal.mixin({
  toast: true,
  position:"top-end",
  showConfirmButton: false,
  timer: 3500,
  timerProgressBar: true,
  didOpen: toast => {
      toast.addEventListener("mouseenter", Swal.stopTimer);
      toast.addEventListener("mouseleave", Swal.resumeTimer);
  }
  });

  export default Toast;