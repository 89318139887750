import React, { useState } from "react";
// import { MdDelete } from "react-icons/md";
// import { AiFillFileImage } from "react-icons/ai";
import {
  Typography,
  Box,
} from "@mui/material";
import cloud from "../../../assets/svgs/document-upload.svg";

function FileUploader() {
  const [selectedFile, setSelectedFile] = useState(null);
  const [fileName, setFileName] = useState("No Selected File");
  const [drop, setDrop] = useState(false);

  //   DRAG AND DROP
  const handleDragOver = (e) => {
    e.preventDefault();
    setDrop(true);
  };
  const handleDragEnter = (e) => {
    e.preventDefault();
    setDrop(true);
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    setDrop(false);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    const fileList = e.dataTransfer.files;
    // eslint-disable-next-line no-unused-expressions
    fileList[0] && setFileName(fileList[0].name);
    console.log(fileList);
    if (fileList) {
      setSelectedFile(URL.createObjectURL(fileList[0]));
    }
    setDrop(false);
  };

  return (

    <Box
      sx={{
        border: "2px dashed #20466f",
        py: 0.5,
        background: drop ? "#626668" : "#fff",
        textAlign: "center",
        borderRadius: 2,
      }}
      onDragEnter={handleDragEnter}
      onDragOver={handleDragOver}
      onDragLeave={handleDragLeave}
      onDrop={handleDrop}
    >
      <form action="">
        <input
          className="custom-file"
          type="file"
          accept="image/*"
          hidden
          onChange={({ target: { files } }) => {
            // eslint-disable-next-line no-unused-expressions
            files[0] && setFileName(files[0].name);
            if (files) {
              setSelectedFile(URL.createObjectURL(files[0]));
            }
          }}
        />
        {selectedFile ? (
          <img src={selectedFile} width={60} height={60} alt={fileName} />
        ) : (
          <Box sx={{ mt: 1, display:"flex", flexDirection:"column", alignItems:"center", justifyContent:"center"}}>
            <img src={cloud} alt="cloud upload" />
            {!drop ? (
              <Typography variant="body2" sx={{ color: "#626668" }}>
                <span
                  role="presentation"
                  onClick={() => document.querySelector(".custom-file").click()}
                  style={{
                    cursor: "pointer",
                    color: "#17324f",
                    textDecoration: "underline",
                    fontWeight:600
                  }}
                >
                  Select a file
                </span>{" "}
                or drag and drop
              </Typography>
            ) : (
              <Typography variant="h6" sx={{ color: "#626668" }}>
                <span style={{ color: "#17324f", cursor: "pointer" }}>
                  Drop File
                </span>{" "}
              </Typography>
            )}
            <Typography variant="body2" style={{ color: "#626668" }}>jpg or png</Typography>
          </Box>
        )}
      </form>


      {selectedFile && (
        <Box
          sx={{
            mt: 1,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            borderRadius: 2,
            background: "skyblue",
          }}
        >
          j{/* <AiFillFileImage color="#1475cf" /> */}
          <span style={{ display: "flex", alignItems: "items-center" }}>
            {fileName} -
            <div
              role="presentation"
              style={{cursor:"pointer"}}
              onClick={() => {
                setFileName("No Selected File");
                setSelectedFile(null);
              }}
            >
              Delete
            </div>
          </span>
        </Box>
      )}
    </Box>
  );
}

export default FileUploader;
