import { useState, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
// @mui
import {
  Stack,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
  FormHelperText,
  Box,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
// components
import Iconify from "../../../components/iconify";
import Toast from "../../../components/ToastAlert";
import ButtonLoader from "../../../components/ButtonLoader";
import axiosClient from "../../../axios-client";
// ----------------------------------------------------------------------

export default function LoginForm() {
  const navigate = useNavigate();

  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [disabledButton, setDisabledButton] = useState(false);

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },

    validationSchema: Yup.object({
      email: Yup.string().email("Invalid email address!").required("Required!"),

      password: Yup.string()
        .required("Password is required!")
        .min(8, "Password is too short - should be 8 characters or longer")
        .required("Required!"),
    }),

    validateOnChange: true,

    onSubmit: (values) => {
      setDisabledButton(true);
      setLoading(true);
      axiosClient
        .post("/v1/auth/login/", values)
        .then((response) => {
          setLoading(false);
          setDisabledButton(false);
          if (response.data.message === "login successful") {
            Toast.fire({
              icon: "success",
              title: `${response.data.message}`,
            });
            navigate("/home");
          }

          const userData = {
            firstName: response.data.data.first_name,
            lastName: response.data.data.last_name,
            userAccess: response.data.data.access,
            refreshToken: response.data.data.refresh,
          };
          localStorage.setItem("userData", JSON.stringify(userData));
          localStorage.setItem("access", response.data.data.access);
          localStorage.setItem("refresh", response.data.data.refresh);
        })

        .catch((err) => {
          setLoading(false);
          setDisabledButton(false);
          let errMessage = "";

          if (err.response) {
            const error = err.response.data.errorMessage;
            // eslint-disable-next-line no-restricted-syntax
            for (const key in error) {
              if (Object.hasOwnProperty.call(error, key)) {
                const element = error[key];
                // eslint-disable-next-line no-const-assign
                errMessage = element.message;
              }
            }
          }

          if (
            err.response &&
            errMessage !== "User not verified, OTP sent to email address"
          ) {
            Toast.fire({
              icon: "error",
              title: `${errMessage || err.response.statusText}`,
            });
          } else if (
            errMessage === "User not verified, OTP sent to email address"
          ) {
            Toast.fire({
              icon: "error",
              title: `${errMessage || err.response.statusText}`,
            });
            navigate("/signup-confirm-code");
          } else if (err.message) {
            Toast.fire({
              icon: "error",
              title: `${err.message}`,
            });
          } else {
            Toast.fire({
              icon: "error",
              title: `${err.message}`,
            });
          }
        });
    },
  });

  useEffect(() => {
    if (!(formik.isValid && formik.dirty)) {
      setDisabledButton(true);
    } else {
      setDisabledButton(false);
    }
  }, [formik.isValid, formik.dirty]);

  return (
    <>
      <Stack sx={{ width: "100%" }} spacing={3}>
        <Box>
          <TextField
            name="email"
            value={formik.values.email.toLowerCase()}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            label="Email"
            fullWidth
          />
          {formik.errors.email && formik.touched.email ? (
            <FormHelperText error> {formik.errors.email}</FormHelperText>
          ) : null}
        </Box>

        <Box>
          <TextField
            fullWidth
            name="password"
            label="Password"
            value={formik.values.password}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            type={showPassword ? "text" : "password"}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={() => setShowPassword(!showPassword)}
                    edge="end"
                  >
                    <Iconify
                      icon={showPassword ? "eva:eye-fill" : "eva:eye-off-fill"}
                    />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          {formik.errors.password && formik.touched.password ? (
            <FormHelperText error> {formik.errors.password}</FormHelperText>
          ) : (
            " "
          )}
        </Box>
      </Stack>

      <Stack alignItems="center">
        <LoadingButton
          sx={{ my: 2, px: 7, mt: 3 }}
          size="large"
          type="submit"
          variant="contained"
          disabled={disabledButton}
          onClick={formik.handleSubmit}
        >
          {!loading ? (
            "Log In"
          ) : (
            <Box sx={{ alignItems: "center" }}>
              <ButtonLoader />
            </Box>
          )}
        </LoadingButton>
      </Stack>

      <Stack
        direction="column"
        alignItems="left"
        justifyContent="space-between"
        sx={{ my: 2, textAlign: "center" }}
      >
        <Typography
          sx={{ "&:hover": { textDecoration: "underline" } }}
          variant="body2"
        >
          <Link
            to="/forgot-password"
            style={{
              textDecoration: "none",
              color: "#17324f",
              fontWeight: "700",
            }}
          >
            Forgot password?
          </Link>
        </Typography>
        <Typography
          variant="body2"
          sx={{ mt: 1, mb: 3, color: "#939697", fontWeight: 700 }}
        >
          Don’t have an Account?{" "}
          <Link
            to="/register"
            variant="subtitle2"
            style={{
              textDecoration: "none",
              color: "#17324f",
              fontWeight: "700",
            }}
            underline="hover"
          >
            {" "}
            Sign Up
          </Link>
        </Typography>
      </Stack>
    </>
  );
}
