import { Stack, Box, Typography } from "@mui/material";
import transfer1 from "../../../assets/svgs/money-send.svg";
import transfer2 from "../../../assets/svgs/money-send (1).svg";

export default function TransferRadio({transfer}) {
  return (
    <>
      <Stack
        direction="row"
        spacing={2}
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          mr: 3,
        }}
      >

{transfer ?         <img src={transfer2} alt="transfer" />
:         <img src={transfer1} alt="transfer" />
}
        <Box>
          <Typography sx={{color:transfer ? "#20466f" : "#939697"}} variant="subtitle2">Bank Transfer</Typography>
          <Typography sx={{color:transfer ? "#20466f" : "#939697"}}variant="caption">
            for you, family or friends{" "}
          </Typography>
        </Box>
      </Stack>
    </>
  );
}
