import { Helmet } from "react-helmet-async";
// @mui
import { styled } from "@mui/material/styles";
import { Typography, Stack, Box, Grid } from "@mui/material";

// components
import Logo from "../../components/logo";

// sections
import { LoginForm } from "../../sections/auth/login";

// ----------------------------------------------------------------------

const FormArea = styled("div")(({ theme }) => ({
  border: "1px solid #E8E8E8",
  borderRadius: "20px",
  padding: "25px ",
  boxShadow: theme.customShadows.card,
  backgroundColor: theme.palette.background.default,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
}));

// ----------------------------------------------------------------------

export default function LoginPage() {
  return (
    <>
      <Helmet>
        <title> Login | Educarts </title>
      </Helmet>

      <Grid container component="main" sx={{ height: "100vh" }}>
        <Grid
          item
          xs={12}
          md={7}
          elevation={6}
          // square
          sx={{
            mt: 8,
            mb: 4,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <FormArea
            sx={{
              maxWidth: { xs: "450px", lg: "500px", xl: "600px" },
              width: { lg: "600px" },
            }}
          >
            <Stack alignItems="center">
              <Logo sx={{ width: 150, mb: 3 }} />
              <Typography variant="h4">Sign in into your Account</Typography>

              <Typography
                variant="body2"
                sx={{ mb: 3, color: "#939697", fontWeight: 500 }}
              >
                Select an account type to log in
              </Typography>
            </Stack>

            <Stack
              direction="row"
              alignItems="center"
              justifyContent="center"
              spacing={2}
              sx={{ mb: 5 }}
            >
              <Box
                size="large"
                color="inherit"
                variant="outlined"
                sx={{
                  borderBottom: "2px solid #20466F",
                  px: 2,
                  py: 1,
                  backgroundColor: "#E9EDF1",
                  borderRadius: 0.5,
                }}
              >
                <Stack
                  direction="row"
                  spacing={1}
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <img src="/assets/educarts/personal.png" alt="personal" />
                  <Typography variant="subtitle2">Personal</Typography>
                </Stack>
              </Box>

              <Box
                size="large"
                color="inherit"
                variant="outlined"
                sx={{
                  px: 2,
                  py: 1,
                }}
              >
                <Stack
                  direction="row"
                  spacing={1}
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <img src="/assets/educarts/institute.png" alt="institute" />
                  <Typography variant="subtitle2">Institute</Typography>
                </Stack>
              </Box>
            </Stack>

            <LoginForm />
          </FormArea>
        </Grid>

        <Grid
          item
          xs={false}
          md={5}
          sx={{
            backgroundImage: "url(/assets/educarts/auth_image.png)",
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        />
      </Grid>
    </>
  );
}
