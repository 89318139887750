import React from "react";
import { Navigate } from "react-router-dom";

const userAuth = () => {
  const userDetails = localStorage.getItem("userData");

  return userDetails;
};
const AuthRoute = ({ children }) => {
  const isAuth = userAuth();

  return !isAuth ? children : <Navigate to="/home" />;
};

export default AuthRoute;