import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  Typography,
  Box,
  Grid,
  MenuItem,
  Divider,
  Popover,
} from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import tuition from "../../assets/svgs/tuition.svg";
import application from "../../assets/svgs/appli.svg";
import sevis from "../../assets/svgs/sevis.svg";
import wes from "../../assets/svgs/cred.svg";
import admission from "../../assets/svgs/admi.svg";

TableContent2.propTypes = {
  heading: PropTypes.string.isRequired,
};

function TableContent2({ heading }) {
  const details = [
    { id: 1, image: tuition, title: "Tuition Fee", link: "View Price" },
    { id: 2, image: application, title: "Application Fee", link: "View Price" },
    { id: 3, image: sevis, title: "Sevis fee", link: "View Price" },
    { id: 4, image: wes, title: "WES fee", link: "View Price" },
    {
      id: 5,
      image: admission,
      title: "Admission Shipment",
      link: "View Price",
    },
  ];

  const [open, setOpen] = useState(null);

  const handleOpenMenu = (event) => {
    setOpen(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setOpen(null);
  };

  return (
    <Grid sx={{}}>
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Typography
          variant="body1"
          sx={{ fontWeight: 500, color: "#000", mb: 1 }}
        >
          {" "}
          {heading}{" "}
        </Typography>
      </Box>

      {/* TABLE */}
      <TableContainer
        sx={{
          backgroundColor: "#fff",
          "&::-webkit-scrollbar": { width: 5, height: 5 },
          "&::-webkit-scrollbar-track": { backgroundColor: "#ccc" },
          "&::-webkit-scrollbar-thumb": {
            backgroundColor: "black",
            borderRadius: 20,
          },
        }}
      >
        <Table>
          <TableBody>
            {details.map((item) => (
              <>
                <TableRow
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                  key={item.id}
                >
                  <TableCell
                    sx={{
                      borderBottom: "none",
                      fontWeight: 500,
                      display: "flex",
                      alignItems: "center",
                      gap: 1,
                      color: "gray",
                    }}
                  >
                    <Box
                      sx={{
                        display: { xs: "none", lg: "block" },
                      }}
                    >
                      <img
                        style={{ width: "90%" }}
                        src={item.image}
                        alt={item.title}
                      />
                    </Box>
                    <Box
                      sx={{
                        display: { lg: "none" },
                      }}
                    >
                      <img src={item.image} alt={item.title} />
                    </Box>
                    {item.title}
                  </TableCell>

                  <TableCell
                    sx={{
                      borderBottom: "none",
                      color: "#20466f",
                      fontWeight: 500,
                      cursor: "pointer",
                    }}
                    onClick={handleOpenMenu}
                    // value={row.id}
                  >
                    View Price
                  </TableCell>
                </TableRow>
                <Divider sx={{ width: "100%" }} />
                <Popover
                  item={item}
                  open={Boolean(open)}
                  anchorEl={open}
                  onClose={handleCloseMenu}
                  anchorOrigin={{ vertical: "top", horizontal: "left" }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  PaperProps={{
                    sx: {
                      p: 1,
                      width: 140,
                      "& .MuiMenuItem-root": {
                        px: 1,
                        typography: "body2",
                        borderRadius: 0.75,
                      },
                    },
                  }}
                >
                  <MenuItem sx={{ color: "success.main" }}>
                    Coming soon
                  </MenuItem>
                  <MenuItem sx={{ color: "success.main" }}>
                    <img src={application} alt="title" />
                  </MenuItem>
                </Popover>
              </>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Grid>
  );
}

export default TableContent2;
